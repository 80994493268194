import React, { Component } from "react";
//import { Container, Row, Col } from "reactstrap";

import "react-responsive-carousel/lib/styles/carousel.min.css";
/*import "./css/luup.css";*/
//import "../.././CSS/luup.css";
import Navbar from ".././Navbar/Navbar";
import Footer from ".././Footer/footer";

class Features extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pos: document.documentElement.scrollTop,
      imglight: true,
      navClass: "",
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > this.state.pos) {
      this.setState({ navClass: "nav-sticky", imglight: false });
    } else {
      this.setState({ navClass: "", imglight: true });
    }
  };
  render() {
    return (
      <div>
        <Navbar navClass={this.state.navClass} imglight={this.state.imglight} />
        <section className="bg-white" style={{paddingTop:100}}>
          <div className="container">
            <div className="row container_padding">
              <div className="col-lg-8 mx-auto text-center">
              <h1 className="section-header text-center">PRIVACY POLICY</h1>
                <hr className="black my-4 color_hr" />
              </div>
            </div>
          </div>

          <div className="container" style={{ paddingTop: 20 }}>
            <div className="row container_padding">
              <div className="col-lg-12 mx-auto">
                <div className="col-lg-10 mx-auto">
                  <h3>Introduction</h3>
                  <p>
                    We respect the privacy and the integrity of any information
                    that you provide in the use of our websites, products, and
                    services. This Privacy Policy applies to Luup, and all other
                    websites, products, and services offered by Eatonomy Corp.
                    It explains our policy regarding the collection, use, and
                    disclosure of your personal information during your use of
                    Luup, and all of software, mobile applications, websites
                    and/or services offered by Eatonomy Corp. It also describes
                    how we collect, use and disclose your personal information
                    and how this personal information can be consulted and
                    corrected when necessary. This policy also explains how
                    cookies and/or log files are used on our Website and/or
                    mobile application. By visiting our websites, apps, agreeing
                    to receive emails from us, or contacting us, you accept the
                    terms and conditions outlined in this policy. This policy
                    does not extend to websites and/or mobile applications
                    operated by third parties, therefore, Eatonomy is not liable
                    for their privacy policies, procedures and practices.
                  </p>
                  <h3>Information we collect</h3>
                  <p>
                    Luup does not collect any personally identifiable
                    information about you when you visit the Website unless you
                    voluntarily provide this information, for example by
                    contacting us through our email forms. By providing
                    information through our email forms you consent to its use
                    to respond to your requests, to contact you and to provide
                    information about Luup’s services.
                  </p>
                  <ul className="square">
                    <li>
                      <span style={{ fontWeight: "bold" }}>
                        Information that you provide to us
                      </span>
                      <br />
                      An example of contact information is an email address. You
                      may also provide us with your name, phone number,
                      username, password or any other information that you
                      directly give us on our website, app, or as a response to
                      Luup’s surveys.
                      <br />
                      <br />
                    </li>
                    <li>
                      <span style={{ fontWeight: "bold" }}>
                        Usage information
                      </span>
                      <br />
                      When you interact with our websites and services, we
                      automatically collect usage information about your
                      interaction with us; for example, the webpages you viewed,
                      where you visited our site from, or what your language
                      preference is.
                      <br />
                      <br />
                    </li>
                    <li>
                      <span style={{ fontWeight: "bold" }}>
                        Device and browser data
                      </span>
                      <br />
                      When you access or products or services, we may collect
                      information from the device and application you use. This
                      may include data like the browser you used, the operating
                      system version, the type of device you used, the device IP
                      address, device ID/MAC address, or the UUID for a mobile
                      device.
                      <br />
                      <br />
                    </li>
                    <li>
                      <span style={{ fontWeight: "bold" }}>Cookies</span>
                      <br />
                      As is standard for most other sites, Luup products may
                      also collect cookies. These cookies cannot be used to
                      extract information, and may only be used to optimize your
                      experience on Luup’s website and/or mobile platforms. You
                      can always configure your browser to disable cookies. We
                      do not use cookies for tracking purposes. You can choose
                      to have your computer warn you each time a cookie is being
                      sent, or you can choose to turn off all cookies. You do
                      this through your browser settings. Since browser is a
                      little different, look at your browser's Help Menu to
                      learn the correct way to modify your cookies. If you turn
                      cookies off, some of the features that make your site
                      experience more efficient may not function properly.
                      <br />
                      <br />
                    </li>
                  </ul>
                  <h3>Use of Collected Information</h3>
                  <p>
                    We may use the information we collect from you, in the
                    following ways:
                  </p>
                  <ul className="square">
                    <li>
                      To allow Luup to provide you with its services and to
                      process and fulfill requests
                    </li>
                    <li>
                      To improve, operate, and maintain our products, services,
                      and websites
                    </li>
                    <li>
                      To troubleshoot and resolve any issues including those
                      related to account activation/deactivation , support,
                      maintenance, and to respond to your inquiries
                    </li>
                    <li>
                      To personalize your experience and to allow us to deliver
                      the type of content and product offerings in which you are
                      most interested
                    </li>
                    <li>
                      To provide customer service and ask for ratings/reviews of
                      services or products
                    </li>
                    <li>
                      To communicate, update, and inform you about any new or
                      existing offers related to product(s) offered by us or our
                      partners, promotions, upcoming events and for any other
                      purposes for which you have provided consent
                    </li>
                    <li>
                      To investigate, mitigate and/or resolve any technical and
                      security issues, and to protect and deter against illegal,
                      unauthorized and/or fraudulent activity
                    </li>
                    <li>
                      For purposes required and/or authorized by law and for
                      responding to legal requests, including ensuring our legal
                      rights are being protected.
                    </li>
                  </ul>

                  <h3>Sharing of Personal Information</h3>
                  <p>
                    Only if you have provided consent, we may share information
                    with others, who, for example, could use it for their own
                    research or marketing uses. Non-personally identifiable
                    (anonymized and/or aggregated) visitor information may be
                    provided to other parties for marketing, advertising, or
                    other uses. We may also have to share information in order
                    to:
                  </p>
                  <ul className="square">
                    <li>
                      Comply with applicable regulations, laws, legal processes,
                      or enforceable governmental requests
                    </li>
                    <li>
                      Detect, investigate and prevent security, fraud, or
                      technical issues
                    </li>
                    <li>
                      Protect the rights and property of our users, agents,
                      customers, the public, Eatonomy Corp., and others. This
                      includes enforcing applicable policies, and/or
                      investigating potential violations.
                    </li>
                    <li>
                      Facilitate a sale, merger or change in control of all or
                      any part of the company, or in preparation for any of
                      these events
                    </li>
                  </ul>

                  <h3>Children’s Online Privacy Protection</h3>
                  <p>
                    The Luup Website and App do not knowingly collect any
                    personal information from children under the age of 13. In
                    the case that we become aware that we have unknowingly
                    collected personal information from a child under the age of
                    13, we will ensure that we make commercially reasonable
                    efforts to delete such personal information from our
                    database.
                  </p>

                  <h3>Third-party links</h3>
                  <p>
                    Occasionally, at our discretion, we may include or offer
                    third-party products or services on our website. These
                    third-party sites have separate and independent privacy
                    policies. We therefore have no responsibility or liability
                    for the content and activities of these linked sites.
                    Nonetheless, we seek to protect the integrity of our site
                    and welcome any feedback about these sites.
                  </p>
                  <h3>Your Right to Access and Modify Information</h3>
                  <p>
                    You can contact us at any time to stop receiving emails from
                    us, to modify your consent for information use and
                    disclosure, to review and/or modify the information we have
                    related to your account, or to close your account; by
                    emailing us at support@getluup.com, or by writing to us at
                    20 Dundas St. W, Suite 921, Toronto, ON M5G2C2. All requests
                    to access and rectify must be accompanied with satisfactory
                    proof of identity. To unsubscribe from receiving promotional
                    emails from us, click the ‘unsubscribe’ button found at the
                    bottom of each email.
                  </p>

                  <h3>Contacting Us</h3>
                  <p>
                    We welcome your questions, comments or feedback regarding
                    this privacy policy. You may contact us using the
                    information below.
                  </p>
                  <p>
                    Eatonomy Corporation
                    <br />
                    20 Dundas St. W<br />
                    Toronto, Ontario M5G2C2
                    <br />
                    Canada
                  </p>

                  <h3>Policy Updates</h3>
                  <p>
                    In the event that this Policy changes in the future, an
                    updated version will be uploaded on our website. This
                    Privacy Policy may be amended from time to time without
                    notice. If we make any changes to this Privacy Policy, we
                    will change the Last Updated date below.
                  </p>

                  <p style={{ fontWeight: "bold" }}>
                    Last Updated: February 7, 2019
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}

export default Features;
