import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import "./CSS/index.css";
import top_pic from "../../../../assets/images/top_pic.svg";
import app_store_pic from "../../../../assets/images/download-app-store-button.svg";
import android_store_pic from "../../../../assets/images/download-play-store-button.svg";

class Section extends Component {
  constructor() {
    super();
    this.state = {
      modal: false,
      activeTab: 1,
    };
  }

  render() {
    return (
      <React.Fragment>
        <section
          style={{ background: "white" }}
          className="section hero-section  background-header"
          id="home"
        >
          <Container className="text-center-mobile-size">
            <Row className="align-items-center">
              <Col lg="5">
                <div className="text-white-50 padding_bottom_mobile">
                  <h1 className="font-weight-bold mb-3  font_top_heading_1">
                    Give Feedback.
                  </h1>
                  <h1 className="font-weight-bold mb-3  font_top_heading_2">
                    Make Bank.
                  </h1>
                  <p className="top_p">
                    Luup rewards you for giving quick feedback to stores you
                    shop at. Stores learn what they need to improve on, and you
                    get gift cards to your favorite brands!
                  </p>

                  <div className="button-items mt-4" style={{ padding: 0 }}>
                    <a
                      className="btn"
                      style={{ padding: 0 }}
                      target="_blank"
                      href="https://itunes.apple.com/ca/app/luup-rewards-for-feedback/id1459323700?mt=8"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={app_store_pic}
                        alt="App Store Download Button"
                        className="download_button_size"
                        //   className="padding2-5Px appdownloadbuttonsize"
                      />
                    </a>

                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.luup.application"
                      rel="noopener noreferrer"
                      className="btn"
                      style={{ padding: 0 }}
                    >
                      <img
                        src={android_store_pic}
                        className="download_button_size"
                        alt="Play Store Download Button"
                        //   className="padding2-5Px appdownloadbuttonsize"
                      />
                    </a>
                  </div>
                </div>
              </Col>
              <Col lg="7" className="ml-lg-auto">
                <img
                  src={top_pic}
                  className="img-fluid width_height_auto"
                  alt=""
                />
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;

{
  /*
                <div className="text-white-50">
                 <h1 className="display-4  font_top_heading_1 font-weight-semibold ">

                  Give Feedback.
                  </h1>
                  <h1 className="display-4 font_top_heading_2 font-weight-semibold">
                  Make Bank.
                  </h1>
            
     <p className="top_p">
                              Luup rewards you for giving quick feedback to
                              stores you shop at. Stores learn what they need to
                              improve on, and you get gift cards to your
                              favorite brands!
                            </p>
                  <div className="button-items mt-4">
                       <div className="padding10Px">
                              <a
                                target="_blank"
                                href="https://itunes.apple.com/ca/app/luup-rewards-for-feedback/id1459323700?mt=8"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={app_store_pic}
                                  alt="App Store Download Button"
                                  className="padding2-5Px appdownloadbuttonsize"
                                />
                              </a>
                              <a
                                target="_blank"
                                href="https://play.google.com/store/apps/details?id=com.luup.application"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={android_store_pic}
                                  alt="Play Store Download Button"
                                  className="padding2-5Px appdownloadbuttonsize"
                                />
                              </a>
                            </div>
                  </div>
                </div>
                */
}
