import React, { Component } from "react";
import Lottie from "react-lottie";

class Customer_Body extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: ""
    };
  }

  getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  }

  componentDidMount() {
    let os = this.getMobileOperatingSystem();
    if (os === "Android") {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.luup.application";
    } else if (os === "iOS") {
      window.location.href =
        "https://itunes.apple.com/ca/app/luup-rewards-for-feedback/id1459323700?mt=8";
    } else {
      window.location.href = "http://www.getluup.com";
    }
  }
  render() {
    return (
      <div style={{ paddingTop: 15 }}>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: require("./loading.json"),
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice"
            }
          }}
          width="40%"
        />
        <p
          className="text-center"
          style={{ fontWeight: "bold", color: "#424242", fontSize: 28 }}
        >
          Redirecting....
        </p>
      </div>
    );
  }
}

export default Customer_Body;
