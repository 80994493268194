import React from "react";
import { Redirect } from "react-router-dom";

//Landing Pages
import Enterprise from "../pages/Landing_Pages/Enterprise/index";
import Small_Businesses from "../pages/Landing_Pages/Small_Businesses/index";
import Education from "../pages/Landing_Pages/Education/index";
import Customer_Landing_Page from "../pages/Landing_Pages/Customer/index";

import APP from "../pages/Landing_Pages/General_Pages/APP/index";
import FAQ from "../pages/Landing_Pages/General_Pages/FAQ/index";
import PRIVACY from "../pages/Landing_Pages/General_Pages/PRIVACY/index";
import SECURITY from "../pages/Landing_Pages/General_Pages/SECURITY/index";
import TERMS from "../pages/Landing_Pages/General_Pages/TERMS/index";
import TERMS_AND_PRIVACY from "../pages/Landing_Pages/General_Pages/TERMS_AND_PRIVACY/index";
import VERIFY from "../pages/Landing_Pages/General_Pages/VERIFY/index";

const publicRoutes = [
  { path: "/verify", component: VERIFY },
  { path: "/terms_and_privacy", component: TERMS_AND_PRIVACY },
  { path: "/terms", component: TERMS },
  { path: "/security", component: SECURITY },
  { path: "/privacy", component: PRIVACY },
  { path: "/faq", component: FAQ },
  { path: "/app", component: APP },
  // { path: "/enterprise", component: Enterprise },
  // { path: "/small-businesses", component: Small_Businesses },
  // { path: "/education", component: Education },

  // { path: "/", component: Customer_Landing_Page },

  { path: "/retail-app", component: Customer_Landing_Page },

  { path: "/", component: Education },

  /*
  { path: "/", component: Customer_Landing_Page },
  { path: "/partner", component: Partner_Landing_Page },
  { path: "/app", component: APP },
  { path: "/faq", component: FAQ },
  { path: "/privacy", component: PRIVACY },
  { path: "/security", component: SECURITY },
  { path: "/terms", component: TERMS },
  { path: "/terms_and_privacy", component: TERMS_AND_PRIVACY },
  { path: "/verify", component: VERIFY },

  */
];

export { publicRoutes };
