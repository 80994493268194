import React, { Component } from "react";

//Import Components
import Navbar from "./Navbar/Navbar";
import Section from "./TopSection/Section";
import CardsMini from "./TopSection/cards-mini";
import AboutUs from "./AboutUs/about-us";
import Main_Content from "./Main_Content/main_content";
import Pricing from "./Pricing/pricing";
import Footer from "./Footer/footer";
import Subscribe from "./Subscribe/subscribe";

class CryptoIcoLanding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pos: document.documentElement.scrollTop,
      imglight: true,
      navClass: "",
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > this.state.pos) {
      this.setState({ navClass: "nav-sticky", imglight: false });
    } else {
      this.setState({ navClass: "", imglight: true });
    }
  };
  render() {
    return (
      <React.Fragment>
        <Navbar navClass={this.state.navClass} imglight={this.state.imglight} />
        <Section />
        <CardsMini />
        <AboutUs />
        <Main_Content />
        <Pricing />
        {/*    <Subscribe />*/}
        <Footer />
      </React.Fragment>
    );
  }
}

export default CryptoIcoLanding;
