import React, { Component } from "react";
//import { Container, Row, Col } from "reactstrap";

import "react-responsive-carousel/lib/styles/carousel.min.css";
/*import "./css/luup.css";*/
//import "../.././CSS/luup.css";

import Lottie from "react-lottie";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

class Features extends Component {
  onChange(value) {
    console.log("Captcha value:", value);
  }
  sendTokenToApp(token) {
    console.log(token);
  }

  render() {
    var container = document.createElement("div");
    container.id = "captcha";
    document.body.appendChild(container);
    var captcha = new firebase.auth.RecaptchaVerifier("captcha", {
      size: "invisible",
      callback(response) {
        console.log(response);

        var baseUri = decodeURIComponent(
          // window.location.search.replace(/^\?appurl/, "")

          window.location.search.replace(/^\?appurl\=/, "")
        );

        window.location.href =
          baseUri + "/?token=" + encodeURIComponent(response);
      },
      expired_callback() {
        console.log("Expired");
      },
    });
    captcha.render().then(function () {
      captcha.verify();
    });

    return (
      <div>
        <div style={{ paddingTop: 15 }}>
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: require("./loading.json"),
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            width="40%"
          />
          <p
            className="text-center"
            style={{ fontWeight: "bold", color: "#424242", fontSize: 28 }}
          >
            Verifying you're not a robot. Please wait...
          </p>
        </div>
      </div>
    );
  }
}

export default Features;
