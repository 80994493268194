import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class NonAuthLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.capitalizeFirstLetter.bind(this);
  }

  capitalizeFirstLetter = (string) => {
    return string.charAt(1).toUpperCase() + string.slice(2);
  };

  componentDidMount() {
    let currentage = this.capitalizeFirstLetter(this.props.location.pathname);

    if (this.props.location.pathname === "/") {
      document.title = "Luup - Real-time Feedback"; //"Luup - Rewards for Feedback";
    } else {
      if (currentage === "Terms_and_privacy") {
        currentage = "Terms & Privacy";
      }
      if (currentage === "Faq") {
        currentage = "FAQ";
      }
      if (currentage === "Small-businesses") {
        currentage = "Small Businesses";
      }

      if (currentage === "Retail-app") {
        currentage = "Luup - Rewards for Feedback";
        document.title = currentage;
      } else {
        document.title = currentage + " | Luup";
      }
    }
  }
  render() {
    return <React.Fragment>{this.props.children}</React.Fragment>;
  }
}

export default withRouter(NonAuthLayout);
