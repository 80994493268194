import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

//Images
import client1 from "../../../../assets/images/clients/1.png";
import client2 from "../../../../assets/images/clients/2.png";
import client3 from "../../../../assets/images/clients/3.png";
import client4 from "../../../../assets/images/clients/4.png";
import client5 from "../../../../assets/images/clients/5.png";
import client6 from "../../../../assets/images/clients/6.png";

class AboutUs extends Component {
  state = {
    step1: true,
    step2: false,
    responsive: {
      576: {
        items: 2,
      },
      768: {
        items: 3,
      },
      992: {
        items: 4,
      },
    },
    clients: [
      { imgUrl: client1 },
      { imgUrl: client2 },
      { imgUrl: client3 },
      { imgUrl: client4 },
      { imgUrl: client5 },
      { imgUrl: client6 },
    ],
  };
  render() {
    return (
      <React.Fragment>
        <section
          style={{ background: "#FFF", paddingTop: 0 }}
          className="section  text-center"
          id="about"
        >
          <Container
            // fluid="lg" className="container_padding"
            id="container_padding"
            fluid={true}
          >
            <Row className="align-items-center">
              <Col lg="12">
                <div
                  className="text-center"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Col lg="8" sm="12">
                    <h1 className="section-heading section-header-size-color">
                      How does it work?
                    </h1>
                    <hr className="black my-4 color_hr" />

                    <p className="top_p">
                      Send your customers short, pre-built and optimized surveys
                      through various channels, and see their feedback in
                      real-time on a live dashboard. Surveys can be displayed
                      in-store via QR codes, added on receipts via a short-link,
                      emailed
                    </p>

                    {/*
                <h1 className="section-heading">
                Make your 2 cents worth a whole lot more!
              </h1>
              <hr className="black my-4 color_hr" />


                  <p className="top_p">
                    Make the services you use and love, even better. You’re already shopping, dining, going places. Share feedback on your day-to-day experiences, and watch your points rack up. Surveys take less than 30 seconds to complete, and you get rewarded every time.
                    </p>
                  */}
                  </Col>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default AboutUs;
