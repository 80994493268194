import React, { Component } from "react";

import FooterLink from "./footer-link";

import {
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  Label,
  Button,
  Input,
  Form,
  FormGroup,
} from "reactstrap";

import { Link } from "react-router-dom";
import classnames from "classnames";
import Lottie from "react-lottie";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

// import "./CSS/index.css";

class Features extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modal_contactUs: false,
      activeTab: 1,
      email: "",
      password: "",
      reEnterPassword: "",
      full_name: "",
      company: "",
      work_email: "",
      message: "",
      plan_selected: "",
      user_type: "retail", //retail//institute
    };
    this.togglemodal_contactUs.bind(this);
  }
  togglemodal_contactUs = () => {
    this.setState((prevState) => ({
      modal_contactUs: !prevState.modal_contactUs,
      activeTab: 1,
      email: "",
      password: "",
      reEnterPassword: "",
      full_name: "",
      company: "",
      work_email: "",
      message: "",
      user_type: "retail",
      //    plan_selected: "",
    }));
  };
  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 3) {
        this.setState({
          activeTab: tab,
        });
      }
    }
  }

  render_sign_up_modal_contactus() {
    return (
      <Modal
        className="outline_class"
        isOpen={this.state.modal_contactUs}
        role="dialog"
        size="lg"
        autoFocus={true}
        centered={true}
        id="verificationModal"
        tabIndex="-1"
        toggle={this.togglemodal_contactUs}
      >
        <div className="modal-content">
          <ModalHeader toggle={this.togglemodal_contactUs}>
            Contact Us
          </ModalHeader>
          <ModalBody>
            <div
              id="kyc-verify-wizard"
              className="twitter-bs-wizard"
              style={{ padding: 0 }}
            >
              <TabContent
                activeTab={this.state.activeTab}
                //  className="twitter-bs-wizard-tab-content"
              >
                <TabPane tabId={1} id="confirm-email">
                  <div>
                    <Form
                      onSubmit={(event) => {
                        event.preventDefault();
                        const { full_name, work_email, message } = this.state;

                        firebase.database().ref("Partner_Contact_us/").push({
                          full_name,
                          work_email,
                          message,
                        });

                        if (this.state.activeTab === 1) {
                          this.toggleTab(this.state.activeTab + 1);
                        }
                      }}
                    >
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <Label for="kycfirstname-input">Full Name</Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="kycfirstname-input"
                              placeholder="Enter Full Name"
                              onChange={(event) => {
                                this.setState({
                                  full_name: event.target.value,
                                });
                              }}
                              value={this.state.full_name}
                              required
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <Label for="kycphoneno-input">Work Email</Label>
                            <Input
                              type="email"
                              className="form-control"
                              id="kycphoneno-input"
                              placeholder="Enter Work Email"
                              onChange={(event) => {
                                this.setState({
                                  work_email: event.target.value,
                                });
                              }}
                              value={this.state.work_email}
                              required
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        {/*<Col lg="6">
                          <FormGroup>
                            <Label for="kycphoneno-input">Email</Label>
                            <Input
                              type="email"
                              className="form-control"
                              id="kycphoneno-input"
                              placeholder="Enter Work Email"
                              onChange={(event) => {
                                this.setState({
                                  work_email: event.target.value,
                                });
                              }}
                              value={this.state.work_email}
                              required
                            />
                          </FormGroup>
                        </Col>*/}
                        <Col lg="12">
                          <FormGroup>
                            <Label for="message">Message (Optional)</Label>
                            <Input
                              // type="tel"
                              id="message"
                              name="message"
                              type="textarea"
                              onChange={(event) => {
                                this.setState({
                                  message: event.target.value,
                                });
                              }}
                              value={this.state.message}
                              //pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12" className="text-center">
                          <Button
                            //className="btn btn-primary w-lg"

                            className="btn  btn-dark  btn-primary btn-block w-lg"
                          >
                            Submit
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </TabPane>

                <TabPane tabId={2} id="personal-info" style={{ padding: 0 }}>
                  <div className="text-center">
                    <h2>Thanks for your interest</h2>

                    {this.state.activeTab === 2 ? (
                      <Lottie
                        options={{
                          loop: false,
                          autoplay: true,
                          animationData: require("./success.json"),
                          rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice",
                          },
                        }}
                        width="40%"
                      />
                    ) : null}
                    <h2>We'll get in touch with you soon!</h2>
                    <br />

                    {/*} <a
                      //  to="#"
                      href="https://partner.getluup.com/"
                      className="btn  btn-dark  btn-primary btn-block w-lg"
                    >
                      CLOSE
                    </a>*/}
                    <Button
                      onClick={(event) => {
                        event.preventDefault();

                        this.togglemodal_contactUs();
                      }}
                      className="btn  btn-dark  btn-primary btn-block w-lg"
                    >
                      Close
                    </Button>
                  </div>
                </TabPane>
              </TabContent>
              <div></div>
            </div>
          </ModalBody>
        </div>
      </Modal>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.render_sign_up_modal_contactus()}
        <footer className="landing-footer">
          <Container
            // fluid="lg" className="container_padding"
            id="container_padding"
            fluid={true}
          >
            <div className="text-center">
              <Row lg="12" sm="12">
                {/*this.state.footerLinks.map((footerLink, key) => (
                  <Col key={key}>
                    <div className="mb-4 mb-lg-0">
                      <h5 className="mb-3 footer-list-title">
                        {footerLink.title}
                      </h5>
                      <ul className="list-unstyled footer-list-menu">
                        {footerLink.links.map((Flink, key) => (
                          <li key={key}>
                            <Link to={Flink.link}>{Flink.title}</Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </Col>
                ))*/}
                <Col lg="12">
                  <div>
                    {/* <h1 className="text-white font-weight-semibold mb-3 hero-title  ">
                      Collected over 100,000 surveys for our clients
                    </h1>*/}
                    <h1 className="section-heading section-header-size-color text-white">
                      Collected over 1,000,000 surveys for our clients!
                    </h1>

                    {/*
                    <h1 className="text-white font-weight-semibold mb-3 hero-title  ">
                      Luup helps you understand your customers
                    </h1>
                    <h1 className="text-white font-weight-semibold mb-3 hero-title  ">
                      before others do!
                    </h1>
                  */}

                    <Row lg="12">
                      <Col lg="12">
                        <div className="button-items mt-4">
                          <Button
                            //className="btn btn-primary w-lg"

                            // className="btn  btn-dark   btn-primary w-lg "

                            className="btn btn-white btn-mid "
                            size="lg"
                            onClick={(event) => {
                              event.preventDefault();

                              this.togglemodal_contactUs();
                            }}
                          >
                            GET YOUR FREE TRIAL TODAY
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>

            <hr className="footer-border my-5" />

            <div className="text-center">
              <div className="site-social-icons">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/getluup/"
                >
                  <i className="bx bxl-facebook"></i>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://twitter.com/getluup"
                >
                  <i className="bx bxl-twitter"></i>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/getluup/"
                >
                  <i className="bx bxl-instagram"></i>
                </a>
              </div>

              <div className="site-copyright">
                <p className="site-copyright">
                  © Copyright {new Date().getFullYear()} Luup. Product of
                  Eatonomy Corp.
                </p>
              </div>
            </div>
          </Container>
        </footer>
      </React.Fragment>
    );
  }
}

export default Features;
