import React, { Component } from "react";
import { Container, Row } from "reactstrap";

//Import Components
import CardBox from "./card-box";

class CardsMini extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coins: [
        {
          title: "Fast and Easy Set-up",
          color: "warning",
          icon: "mdi mdi-clock-fast",
          value:
            "Luup can be set up in minutes, with no technical knowledge needed.",
          rate: "0.0012.23 ( 0.2 % )",
          isIncrease: true,
        },

        {
          title: "Fully Optimized",
          color: "primary",
          icon: "mdi mdi-ethereum",
          value:
            "All Luup surveys are fully optimized for mobile, desktop and tablet -  so you can offer a seamless and intuitive experience",
          rate: "004.12 ( 0.1 % )",
          isIncrease: false,
        },
        {
          title: "Pre-Built Templates",
          color: "info",
          icon: "mdi mdi-litecoin",
          value:
            "We provide instantly deployable survey templates for Net Promoter Score (NPS), Customer Satisfaction CSAT, and more!",
          rate: "0.0001.12 ( 0.1 % ) ",
          isIncrease: true,
        },
        {
          title: "Fun and Rewarding",
          color: "info",
          icon: "mdi mdi-litecoin",
          value:
            "Encourage more customers to leave detailed feedback, by providing instant draws and prizes that gamify the feedback experience",
          rate: "0.0001.12 ( 0.1 % ) ",
          isIncrease: true,
        },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className="section bg-white p-0">
          <Container
            id="container_padding"
            fluid={true}
            // fluid="lg"
            // className="container_padding"
            // className="themed-container"
            // fluid={true}
            // style={{ paddingRight: 100, paddingLeft: 100 }}
          >
            <div className="currency-price">
              <Row>
                {/* reder card boxes */}
                <CardBox coins={this.state.coins} />
              </Row>
            </div>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default CardsMini;
