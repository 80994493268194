import React, { Component } from "react";
import { Container, Row } from "reactstrap";

//Import Components
import CardBox from "./card-box";

class CardsMini extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coins: [
        {
          title: "Develop Data-Driven Teaching Plans",
          color: "warning",
          icon: "bx bx-book",
          value:
            "Track classroom understanding after each lesson, and measure the effectiveness of different teaching methods and strategies, so you can develop a lesson plan and materials that work best for your students’ needs.",
          rate: "0.0012.23 ( 0.2 % )",
          isIncrease: true,
        },

        {
          title: "Gauge Student Sentiment",
          color: "primary",
          icon: "bx bx-happy",
          value:
            "How satisfied are your students with your class? What do they understand well? Where do they need your help? What questions do they still have? How can you help them learn better? Let them tell you directly!",
          rate: "004.12 ( 0.1 % )",
          isIncrease: false,
        },
        {
          title: "Turn Student Feedback into a KPI",
          color: "info",
          icon: "bx bx-trending-up",
          value:
            "Collect real-time student feedback, and turn it into a meaningful KPI for teaching effectiveness.  Track your progress as an educator over time, so you can try different strategies until you find out what works best for your students.",
          rate: "0.0001.12 ( 0.1 % ) ",
          isIncrease: true,
        },
        {
          title: "Anonymous and Sincere",
          color: "info",
          icon: "bx bxs-lock",
          value:
            "We do not track students, which allows them to honestly and openly share their feedback, comments, questions, and feelings without any hesitation or fear of judgement, in a non-intimidating environment",
          rate: "0.0001.12 ( 0.1 % ) ",
          isIncrease: true,
        },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className="section bg-white p-0">
          <Container
            id="container_padding"
            fluid={true}
            // fluid="lg"
            // className="container_padding"
            // className="themed-container"
            // fluid={true}
            // style={{ paddingRight: 100, paddingLeft: 100 }}
          >
            <div className="currency-price">
              <Row>
                {/* reder card boxes */}
                <CardBox coins={this.state.coins} />
              </Row>
            </div>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default CardsMini;
