import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,

  //
  //Container,
  //Row,
  //Col,
  //Card,
  //CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  //Button,
  Input,
  Form,
  FormGroup,
} from "reactstrap";

import classnames from "classnames";
import { Link as ScrollLink } from "react-scroll";

import { Link } from "react-router-dom";
import Lottie from "react-lottie";
import "./CSS/index.css";

//Import Countdown
import Countdown from "react-countdown";

class Section extends Component {
  constructor() {
    super();
    this.state = {
      modal: false,
      activeTab: 1,
    };
    this.renderer.bind(this);
    this.togglemodal.bind(this);
    this.toggleTab.bind(this);
  }

  togglemodal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 3) {
        this.setState({
          activeTab: tab,
        });
      }
    }
  }
  componentDidMount() {
    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }
  render_sign_up_modal() {
    return (
      <Modal
        className="outline_class"
        isOpen={this.state.modal}
        role="dialog"
        size="lg"
        autoFocus={true}
        centered={true}
        id="verificationModal"
        tabIndex="-1"
        toggle={this.togglemodal}
      >
        <div className="modal-content">
          <ModalHeader toggle={this.togglemodal}>
            Verify your Account
          </ModalHeader>
          <ModalBody>
            <div id="kyc-verify-wizard" className="twitter-bs-wizard">
              <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === 1,
                    })}
                    onClick={() => {
                      this.toggleTab(2);
                    }}
                  >
                    <span className="step-number mr-2">01</span>
                    Account Creation
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === 2,
                    })}
                    onClick={() => {
                      this.toggleTab(1);
                    }}
                  >
                    <span className="step-number mr-2">02</span>
                    Business Information
                  </NavLink>
                </NavItem>
              </ul>
              <TabContent
                activeTab={this.state.activeTab}
                className="twitter-bs-wizard-tab-content"
              >
                <TabPane tabId={1} id="confirm-email">
                  <div>
                    <Form>
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <Label for="kycemail-input">Email</Label>
                            <Input
                              type="email"
                              className="form-control"
                              id="kycemail-input"
                              placeholder="Enter Email Address"
                            />
                          </FormGroup>

                          <FormGroup>
                            <Label for="kycconfirmcode-input">
                              Confirm code
                            </Label>
                            <Input
                              type="email"
                              className="form-control"
                              id="kycconfirmcode-input"
                              placeholder="Enter Confirm code"
                            />
                          </FormGroup>

                          <div className="mb-3">
                            Didn't recieve code ?
                            <Button type="button" color="link">
                              Resend Code
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </TabPane>

                <TabPane tabId={2} id="personal-info">
                  <Form>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <Label for="kycfirstname-input">First name</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="kycfirstname-input"
                            placeholder="Enter First name"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <Label for="kyclastname-input">Last name</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="kyclastname-input"
                            placeholder="Enter Last name"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <Label for="kycphoneno-input">Phone</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="kycphoneno-input"
                            placeholder="Enter Phone number"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <Label for="kycbirthdate-input">Date of birth</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="kycbirthdate-input"
                            placeholder="Enter Date of birth"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <Label for="kycselectcity-input">City</Label>
                          <select
                            className="custom-select"
                            id="kycselectcity-input"
                          >
                            <option value="1" defaultValue>
                              San Francisco
                            </option>
                            <option value="2">Los Angeles</option>
                            <option value="3">San Diego</option>
                          </select>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </TabPane>
              </TabContent>
              <ul className="pager wizard twitter-bs-wizard-pager-link">
                <li
                  className={
                    this.state.activeTab === 1
                      ? "previous disabled"
                      : "previous"
                  }
                >
                  <Link
                    to="#"
                    onClick={() => {
                      this.toggleTab(this.state.activeTab - 1);
                    }}
                  >
                    Previous
                  </Link>
                </li>
                <li
                  className={
                    this.state.activeTab === 3 ? "next disabled" : "next"
                  }
                >
                  <Link
                    to="#"
                    onClick={() => {
                      this.toggleTab(this.state.activeTab + 1);
                    }}
                  >
                    Next
                  </Link>
                </li>
              </ul>
            </div>
          </ModalBody>
        </div>
      </Modal>
    );
  }

  renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <span>You are good to go!</span>;
    } else {
      // Render a countdown
      return (
        <>
          <div className="coming-box">
            {days}
            <span>Days</span>
          </div>
          <div className="coming-box">
            {hours}
            <span>Hours</span>
          </div>
          <div className="coming-box">
            {minutes}
            <span>Minutes</span>
          </div>
          <div className="coming-box">
            {seconds}
            <span>Seconds</span>
          </div>
        </>
      );
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.render_sign_up_modal()}

        <section className="section hero-section " id="home">
          <div
            //className="bg-overlay bg-primary"
            className="bg-overlay "
            style={{ background: "#4700EE" }}
          />
          <Container
            //  className="themed-container"
            // fluid="lg"

            id="container_padding"
            fluid={true}
            // className="themed-container"
            // fluid={true}
            //style={{ paddingRight: 100, paddingLeft: 100 }}
          >
            <Row className="align-items-center">
              <Col lg="5" md="12" sm="12">
                <div className="text-white-50 text-center-mobile-size">
                  <h1 className="text-white font-weight-semibold mb-3 hero-title">
                    Keep a Pulse on Customer Happiness
                  </h1>
                  {/* <h1 className="text-white font-weight-semibold mb-3 hero-title">
                    THE GOOD. THE BAD.
    </h1>*/}
                  <p className="font-size-18" style={{ color: "white" }}>
                    In-store experience is critical. Use customer feedback to
                    understand what your customers want, what they like, and
                    what they wish you could improve on.
                  </p>

                  <div className="button-items mt-4">
                    {/*} <Link
                      //ScrollLink
                      //onClick={this.togglemodal}

                      className="btn btn-light"
                    >
                      Get Free Trial
                    </Link>*/}

                    <ScrollLink
                      className="btn btn-light"
                      style={{ color: "black" }}
                      to="pricing"
                      smooth={true}
                      duration={300}
                    >
                      Get Free Trial
                    </ScrollLink>
                  </div>
                </div>
              </Col>
              <Col lg="5" md="12" sm="12" className="ml-lg-auto">
                <div
                // className="text-center-mobile-size"
                >
                  <Card
                    className="overflow-hidden mb-0 mt-5 mt-lg-0"
                    // style={{ background: "#4701ed" }}
                  >
                    <Lottie
                      options={{
                        loop: true,
                        autoplay: true,
                        animationData: require("./feedback.json"),
                        rendererSettings: {
                          preserveAspectRatio: "xMidYMid slice",
                        },
                      }}
                      // width="65%"
                    />
                    {/*<CardHeader className="text-center">
                    <h5 className="mb-0">ICO Countdown time</h5>
                  </CardHeader>
                  <CardBody>
                    <div className="text-center">
                      <h5>Time left to Ico :</h5>
                      <div className="mt-4">
                        <div className="counter-number ico-countdown">
                          <Countdown
                            date="2020/12/31"
                            renderer={this.renderer}
                          />
                        </div>
                      </div>

                      <div className="mt-4">
                        <Button type="button" color="success" className="w-md">
                          Get Token
                        </Button>
                      </div>

                      <div className="mt-5">
                        <h4 className="font-weight-semibold">
                          1 ETH = 2235 SKT
                        </h4>
                        <div className="clearfix mt-4">
                          <h5 className="float-right font-size-14">5234.43</h5>
                        </div>
                        <div className="progress p-1 progress-xl softcap-progress">
                          <div
                            className="progress-bar bg-info"
                            role="progressbar"
                            style={{ width: "15%" }}
                            aria-valuenow="15"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          >
                            <div className="progress-label">15 %</div>
                          </div>
                          <div
                            className="progress-bar progress-bar-striped progress-bar-animated"
                            role="progressbar"
                            style={{ width: "30%" }}
                            aria-valuenow="30"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          >
                            <div className="progress-label">30 %</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardBody>*/}
                  </Card>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
