import React, { Component } from "react";
import {
  Container,
  Row,
  Col,

  //
  //Container,
  //Row,
  //Col,
  // Card,
  // CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  // NavItem,
  // NavLink,
  Label,
  Button,
  Input,
  Form,
  FormGroup,
} from "reactstrap";

import { Link } from "react-router-dom";
import classnames from "classnames";
import Lottie from "react-lottie";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

import "./CSS/index.css";

class OurTeam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modal_contactUs: false,
      activeTab: 1,
      email: "",
      password: "",
      reEnterPassword: "",
      full_name: "",
      company: "",
      work_email: "",
      work_phone: "",
      plan_selected: "",
      user_type: "retail", //retail//institute
    };
    this.togglemodal.bind(this);
    this.togglemodal_contactUs.bind(this);
    this.toggleTab.bind(this);
  }

  togglemodal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
      activeTab: 1,
      email: "",
      password: "",
      reEnterPassword: "",
      full_name: "",
      company: "",
      work_email: "",
      work_phone: "",
      user_type: "retail",
      // plan_selected: "",
    }));
  };

  togglemodal_contactUs = () => {
    this.setState((prevState) => ({
      modal_contactUs: !prevState.modal_contactUs,
      activeTab: 1,
      email: "",
      password: "",
      reEnterPassword: "",
      full_name: "",
      company: "",
      work_email: "",
      work_phone: "",
      user_type: "retail",
      //    plan_selected: "",
    }));
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 3) {
        this.setState({
          activeTab: tab,
        });
      }
    }
  }

  render_sign_up_modal_contactus() {
    return (
      <Modal
        className="outline_class"
        isOpen={this.state.modal_contactUs}
        role="dialog"
        size="lg"
        autoFocus={true}
        centered={true}
        id="verificationModal"
        tabIndex="-1"
        toggle={this.togglemodal_contactUs}
      >
        <div className="modal-content">
          <ModalHeader toggle={this.togglemodal_contactUs}>
            Contact Us
          </ModalHeader>
          <ModalBody>
            <div
              id="kyc-verify-wizard"
              className="twitter-bs-wizard"
              style={{ padding: 0 }}
            >
              <TabContent
                activeTab={this.state.activeTab}
                //  className="twitter-bs-wizard-tab-content"
              >
                <TabPane tabId={1} id="confirm-email">
                  <div>
                    <Form
                      onSubmit={(event) => {
                        event.preventDefault();
                        const {
                          full_name,
                          comapany,
                          work_email,
                          work_phone,
                        } = this.state;

                        firebase.database().ref("Partner_Contact_us/").push({
                          full_name,
                          comapany,
                          work_email,
                          work_phone,
                        });

                        if (this.state.activeTab === 1) {
                          this.toggleTab(this.state.activeTab + 1);
                        }
                      }}
                    >
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <Label for="kycfirstname-input">Full Name</Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="kycfirstname-input"
                              placeholder="Enter Full Name"
                              onChange={(event) => {
                                this.setState({
                                  full_name: event.target.value,
                                });
                              }}
                              value={this.state.full_name}
                              required
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <Label for="kyclastname-input">Company</Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="kyclastname-input"
                              placeholder="Enter Company"
                              onChange={(event) => {
                                this.setState({
                                  company: event.target.value,
                                });
                              }}
                              value={this.state.company}
                              required
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <Label for="kycphoneno-input">Work Email</Label>
                            <Input
                              type="email"
                              className="form-control"
                              id="kycphoneno-input"
                              placeholder="Enter Work Email"
                              onChange={(event) => {
                                this.setState({
                                  work_email: event.target.value,
                                });
                              }}
                              value={this.state.work_email}
                              required
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <Label for="phone">Work Phone (Optional)</Label>
                            <Input
                              type="tel"
                              id="phone"
                              name="phone"
                              onChange={(event) => {
                                this.setState({
                                  work_phone: event.target.value,
                                });
                              }}
                              value={this.state.work_phone}
                              //pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12" className="text-center">
                          <Button
                            //className="btn btn-primary w-lg"

                            className="btn  btn-dark  btn-primary btn-block w-lg"
                          >
                            Submit
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </TabPane>

                <TabPane tabId={2} id="personal-info" style={{ padding: 0 }}>
                  {/** */}
                  <div className="text-center">
                    <h2>Thanks for your interest</h2>

                    {this.state.activeTab === 2 ? (
                      <Lottie
                        options={{
                          loop: false,
                          autoplay: true,
                          animationData: require("./success.json"),
                          rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice",
                          },
                        }}
                        width="40%"
                      />
                    ) : null}
                    <h2>We'll get in touch with you soon!</h2>
                    <br />

                    <a
                      //  to="#"
                      href="https://partner.getluup.com/"
                      className="btn  btn-dark  btn-primary btn-block w-lg"
                      /* onClick={() => {
                      if (this.state.activeTab !== 2) {
                        this.toggleTab(this.state.activeTab + 1);
                      }
                    }}*/
                    >
                      CLOSE
                    </a>
                  </div>
                </TabPane>
              </TabContent>
              <div></div>
            </div>
          </ModalBody>
        </div>
      </Modal>
    );
  }

  render_sign_up_modal() {
    var regex = /[^\w\s]/g;

    return (
      <Modal
        className="outline_class"
        isOpen={this.state.modal}
        role="dialog"
        size="lg"
        autoFocus={true}
        centered={true}
        id="verificationModal"
        tabIndex="-1"
        toggle={this.togglemodal}
      >
        <div className="modal-content">
          <ModalHeader toggle={this.togglemodal}>
            Luup Account Creation ({this.state.plan_selected})
          </ModalHeader>
          <ModalBody>
            <div
              id="kyc-verify-wizard"
              className="twitter-bs-wizard"
              style={{ padding: 0 }}
            >
              <TabContent
                activeTab={this.state.activeTab}
                //  className="twitter-bs-wizard-tab-content"
              >
                <TabPane tabId={1} id="confirm-email">
                  <div>
                    <Form
                      onSubmit={(event) => {
                        event.preventDefault();

                        if (this.state.activeTab === 1) {
                          const {
                            plan_selected,
                            email,
                            password,
                            full_name,
                            user_type,
                            company,
                          } = this.state;
                          // var displayName = full_name;
                          var company_or_institution_name = company;
                          if (user_type === "") {
                            alert(
                              "Please select  business type  before  proceeding"
                            );
                          } else {
                            if (this.state.company.search(regex) !== -1) {
                              alert(
                                "To continue please avoid using special characters."
                              );
                            } else {
                              firebase
                                .database()
                                .ref(
                                  `QR_Code/List_Of_Companies/list/${company}`
                                )
                                .once("value")
                                .then((snap) => {
                                  var snapshot = snap.val();
                                  if (snapshot === null) {
                                    firebase
                                      .auth()
                                      .createUserWithEmailAndPassword(
                                        email,
                                        password
                                      )
                                      .then((data) => {
                                        //some
                                        this.toggleTab(
                                          this.state.activeTab + 1
                                        );

                                        firebase
                                          .database()
                                          .ref(`partner/users/${data.user.uid}`)
                                          .update({
                                            full_name,
                                            plan_selected,
                                            dashboard_access: { access: true },
                                            qr_access_granted: { access: true },
                                            role: "Program Manager",
                                            user_type: user_type,
                                            company_or_institution_name,
                                            all_programs: {
                                              [company]: { access: company },
                                            },
                                          });
                                      })
                                      .catch((error) => {
                                        // Handle Errors here.
                                        var errorCode = error.code;
                                        var errorMessage = error.message;

                                        alert("ERROR: " + errorMessage);

                                        // ...
                                      });
                                  } else {
                                    alert(
                                      company +
                                        " is already in use  by  another company/business. Please use a  different name."
                                    );
                                  }
                                });
                            }
                          }
                        }
                      }}
                    >
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <Label for="full_name">Full Name</Label>
                            <Input
                              type="name"
                              className="form-control"
                              id="full_name"
                              placeholder="Enter Full Name"
                              value={this.state.full_name}
                              onChange={(event) => {
                                event.preventDefault();
                                this.setState({
                                  full_name: event.target.value,
                                });
                              }}
                              required
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for="company">
                              Comapny Name (Unique Name e.g Bob Bakery, Dental
                              Services Downtown)
                            </Label>
                            <Input
                              type="company"
                              className="form-control"
                              id="company"
                              placeholder="Enter Company Name"
                              value={this.state.company}
                              onChange={(event) => {
                                event.preventDefault();
                                this.setState({
                                  company: event.target.value,
                                });
                              }}
                              required
                            />
                          </FormGroup>

                          {this.state.company.search(regex) !== -1 ? (
                            <p style={{ color: "#B00020", fontSize: 12 }}>
                              To continue please avoid using special characters.
                            </p>
                          ) : null}

                          {/*
                          <FormGroup>
                            <Label for="business-type">Business Type</Label>

                            <select
                              className="custom-select"
                              id="business-type"
                              onChange={(event) => {
                                event.preventDefault();
                                this.setState({
                                  user_type: event.target.value,
                                });
                              }}
                            >
                              <option value="" defaultValue>
                                Select...
                              </option>
                              <option value="retail">
                                Retail, Barber Shop, Dental Office, Nail Salon,
                                etc
                              </option>
                              <option value="institute">
                                Work Shop, Institute, etc
                              </option>
                            </select>
                          </FormGroup>
                            */}
                          <FormGroup>
                            <Label for="email">Email</Label>
                            <Input
                              type="email"
                              className="form-control"
                              id="email"
                              placeholder="Enter Email Address"
                              value={this.state.email}
                              onChange={(event) => {
                                event.preventDefault();
                                this.setState({ email: event.target.value });
                              }}
                              required
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for="enterPasssword">Password</Label>
                            <Input
                              type="password"
                              className="form-control"
                              id="enterPasssword"
                              placeholder="Enter Password"
                              value={this.state.password}
                              onChange={(event) => {
                                event.preventDefault();
                                this.setState({ password: event.target.value });
                              }}
                              required
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for="re_enterPasssword">
                              Re-Enter Password
                            </Label>
                            <Input
                              type="password"
                              className="form-control"
                              id="re_enterPasssword"
                              placeholder="Enter Password"
                              value={this.state.reEnterPassword}
                              onChange={(event) => {
                                event.preventDefault();
                                this.setState({
                                  reEnterPassword: event.target.value,
                                });
                              }}
                              required
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      {this.state.reEnterPassword ===
                      this.state.password ? null : (
                        <div>
                          {this.state.reEnterPassword !== "" &&
                          this.state.password !== "" ? (
                            <p style={{ color: "#B00020", fontSize: 12 }}>
                              Password do not match
                            </p>
                          ) : null}
                        </div>
                      )}

                      {this.state.reEnterPassword === this.state.password ? (
                        <div>
                          {this.state.reEnterPassword !== "" &&
                          this.state.password !== "" ? (
                            <Button
                              type="submit"
                              className="btn  btn-dark  btn-primary btn-block w-lg"
                            >
                              Create Account
                            </Button>
                          ) : (
                            <Button
                              type="submit"
                              className="btn  btn-dark  btn-primary btn-block w-lg"
                              disabled
                            >
                              Create Account
                            </Button>
                          )}
                        </div>
                      ) : (
                        <Button
                          type="submit"
                          className="btn  btn-dark  btn-primary btn-block w-lg"
                          disabled
                        >
                          Create Account
                        </Button>
                      )}
                    </Form>
                  </div>
                </TabPane>

                <TabPane tabId={2} id="personal-info" style={{ padding: 0 }}>
                  {/** */}
                  <div className="text-center">
                    <h2>Thanks for signing up!</h2>

                    {this.state.activeTab === 2 ? (
                      <Lottie
                        options={{
                          loop: false,
                          autoplay: true,
                          animationData: require("./success.json"),
                          rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice",
                          },
                        }}
                        width="40%"
                      />
                    ) : null}
                    <h2>You're all good to go</h2>
                    <br />

                    <a
                      //  to="#"
                      href="https://partner.getluup.com/"
                      className="btn  btn-dark  btn-primary btn-block w-lg"
                      /* onClick={() => {
                      if (this.state.activeTab !== 2) {
                        this.toggleTab(this.state.activeTab + 1);
                      }
                    }}*/
                    >
                      Proceed to the Dashboard
                    </a>
                  </div>
                </TabPane>
              </TabContent>
              <div>
                {/*this.state.activeTab === 2 ? (
                  <a
                    //  to="#"
                    href="https://partner.getluup.com/"
                    className="btn  btn-dark  btn-primary btn-block w-lg"
                    onClick={() => {
                      if (this.state.activeTab !== 2) {
                        this.toggleTab(this.state.activeTab + 1);
                      }
                    }}
                  >
                    Head over to the Dashboard
                  </a>
                ) : (
                  <Link
                    to="#"
                    className="btn  btn-dark  btn-primary btn-block w-lg"
                    onClick={() => {
                      if (this.state.activeTab === 1) {
                        firebase
                          .auth()
                          .createUserWithEmailAndPassword(email, password)
                          .then(() => {
                            //some
                          })
                          .catch((error) => {
                            // Handle Errors here.
                            var errorCode = error.code;
                            var errorMessage = error.message;
                            // ...
                          });
                      } else {
                        if (this.state.activeTab !== 2) {
                          this.toggleTab(this.state.activeTab + 1);
                        }
                      }
                    }}
                  >
                    Create Account
                  </Link>
                  )*/}
              </div>
            </div>
          </ModalBody>
        </div>
      </Modal>
    );
  }

  render_pricing() {
    return (
      <div className="hori-timeline">
        <div
          className="owl-carousel owl-theme  navs-carousel events pricing"
          id="timeline-carousel"
        >
          <Row>
            <Col md={4}>
              <div className="item">
                <div className="card text-center team-box">
                  <div
                    style={{
                      background: "#311b92",
                      color: "white",
                      fontSize: 15,
                    }}
                  >
                    <span>Free Month Trial</span>
                  </div>
                  <div className="card-body">
                    {/*<div>
                      <img src={avatar2} alt="" className="rounded" />
                    </div>*/}

                    <div className="mt-3">
                      <h3>Starter</h3>
                      <h1>
                        $80 CAD
                        <span
                          className="text-muted mb-0"
                          style={{ fontSize: 15 }}
                        >
                          /month
                        </span>
                      </h1>

                      <hr />
                      {/**************/}

                      <ul className="fa-ul text-left">
                        <li>
                          <p className="mb-0">
                            <span className="fa-li">
                              <i className="fas fa-check"></i>
                            </span>
                            Single User
                          </p>
                        </li>
                        <li>
                          <p className="mb-0">
                            <span className="fa-li">
                              <i className="fas fa-check"></i>
                            </span>
                            5GB Storage
                          </p>
                        </li>
                        <li>
                          <p className="mb-0">
                            <span className="fa-li">
                              <i className="fas fa-check"></i>
                            </span>
                            Unlimited Public Projects
                          </p>
                        </li>
                        <li>
                          <p className="mb-0">
                            <span className="fa-li">
                              <i className="fas fa-check"></i>
                            </span>
                            Community Access
                          </p>
                        </li>
                        <li className="text-muted">
                          <p className="mb-0 text-muted">
                            <span className="fa-li">
                              <i className="fas fa-times"></i>
                            </span>
                            Unlimited Private Projects
                          </p>
                        </li>
                        <li className="text-muted">
                          <p className="mb-0 text-muted">
                            <span className="fa-li">
                              <i className="fas fa-times"></i>
                            </span>
                            Dedicated Phone Support
                          </p>
                        </li>
                        <li className="text-muted">
                          <p className="mb-0 text-muted">
                            <span className="fa-li">
                              <i className="fas fa-times"></i>
                            </span>
                            Free Subdomain
                          </p>
                        </li>
                        <li className="text-muted">
                          <p className="mb-0 text-muted">
                            <span className="fa-li">
                              <i className="fas fa-times"></i>
                            </span>
                            Monthly Status Reports
                          </p>
                        </li>
                      </ul>
                      {/**************/}
                    </div>
                  </div>
                  <div className="card-footer bg-transparent border-top">
                    <div className="d-flex mb-0 team-social-links">
                      <div className="flex-fill">
                        <Link
                          to="#"
                          data-toggle="tooltip"
                          // className="btn btn-block btn-primary text-uppercase "
                          className="btn  btn-dark  btn-primary btn-block "
                          style={{ color: "white" }}
                          onClick={() => {
                            this.setState({
                              plan_selected: "Starter",
                            });
                            this.togglemodal();
                          }}
                        >
                          Get Started
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="item">
                <div className="card text-center team-box">
                  <div
                    style={{
                      background: "#311b92",
                      color: "white",
                      fontSize: 15,
                    }}
                  >
                    <span>Free Month Trial</span>
                  </div>
                  <div className="card-body">
                    {/*<div>
                      <img src={avatar3} alt="" className="rounded" />
                   </div>*/}

                    <div className="mt-3">
                      <h3>Growth</h3>
                      <h1>
                        $125 CAD
                        <span
                          className="text-muted mb-0"
                          style={{ fontSize: 15 }}
                        >
                          /month
                        </span>
                      </h1>
                      <hr />
                      {/**************/}

                      <ul className="fa-ul text-left">
                        <li>
                          <p className="mb-0">
                            <span className="fa-li">
                              <i className="fas fa-check"></i>
                            </span>
                            Single User
                          </p>
                        </li>
                        <li>
                          <p className="mb-0">
                            <span className="fa-li">
                              <i className="fas fa-check"></i>
                            </span>
                            5GB Storage
                          </p>
                        </li>
                        <li>
                          <p className="mb-0">
                            <span className="fa-li">
                              <i className="fas fa-check"></i>
                            </span>
                            Unlimited Public Projects
                          </p>
                        </li>
                        <li>
                          <p className="mb-0">
                            <span className="fa-li">
                              <i className="fas fa-check"></i>
                            </span>
                            Community Access
                          </p>
                        </li>
                        <li className="text-muted">
                          <p className="mb-0 text-muted">
                            <span className="fa-li">
                              <i className="fas fa-times"></i>
                            </span>
                            Unlimited Private Projects
                          </p>
                        </li>
                        <li className="text-muted">
                          <p className="mb-0 text-muted">
                            <span className="fa-li">
                              <i className="fas fa-times"></i>
                            </span>
                            Dedicated Phone Support
                          </p>
                        </li>
                        <li className="text-muted">
                          <p className="mb-0 text-muted">
                            <span className="fa-li">
                              <i className="fas fa-times"></i>
                            </span>
                            Free Subdomain
                          </p>
                        </li>
                        <li className="text-muted">
                          <p className="mb-0 text-muted">
                            <span className="fa-li">
                              <i className="fas fa-times"></i>
                            </span>
                            Monthly Status Reports
                          </p>
                        </li>
                      </ul>
                      {/**************/}
                    </div>
                  </div>
                  <div className="card-footer bg-transparent border-top">
                    <div className="d-flex mb-0 team-social-links">
                      <div className="flex-fill">
                        <Link
                          to="#"
                          data-toggle="tooltip"
                          // className="btn btn-block btn-primary text-uppercase "
                          className="btn  btn-dark  btn-primary btn-block "
                          style={{ color: "white" }}
                          onClick={() => {
                            this.setState({
                              plan_selected: "MidRange",
                            });
                            this.togglemodal();
                          }}
                        >
                          Get Started
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="item">
                <div className="card text-center team-box">
                  <div
                    style={{
                      background: "#311b92",
                      color: "white",
                      fontSize: 15,
                    }}
                  >
                    <span>Free Month Trial</span>
                  </div>
                  <div className="card-body">
                    {/* <div>
                      <img src={avatar8} alt="" className="rounded" />
                   </div>*/}
                    <div className="mt-3">
                      <h3>Enterprise</h3>
                      <p className="text-muted mb-0">Contact Us</p>

                      <hr />

                      {/**************/}

                      <ul className="fa-ul text-left">
                        <li>
                          <p className="mb-0">
                            <span className="fa-li">
                              <i className="fas fa-check"></i>
                            </span>
                            Single User
                          </p>
                        </li>
                        <li>
                          <p className="mb-0">
                            <span className="fa-li">
                              <i className="fas fa-check"></i>
                            </span>
                            5GB Storage
                          </p>
                        </li>
                        <li>
                          <p className="mb-0">
                            <span className="fa-li">
                              <i className="fas fa-check"></i>
                            </span>
                            Unlimited Public Projects
                          </p>
                        </li>
                        <li>
                          <p className="mb-0">
                            <span className="fa-li">
                              <i className="fas fa-check"></i>
                            </span>
                            Community Access
                          </p>
                        </li>
                        <li className="text-muted">
                          <p className="mb-0 text-muted">
                            <span className="fa-li">
                              <i className="fas fa-times"></i>
                            </span>
                            Unlimited Private Projects
                          </p>
                        </li>
                        <li className="text-muted">
                          <p className="mb-0 text-muted">
                            <span className="fa-li">
                              <i className="fas fa-times"></i>
                            </span>
                            Dedicated Phone Support
                          </p>
                        </li>
                        <li className="text-muted">
                          <p className="mb-0 text-muted">
                            <span className="fa-li">
                              <i className="fas fa-times"></i>
                            </span>
                            Free Subdomain
                          </p>
                        </li>
                        <li className="text-muted">
                          <p className="mb-0 text-muted">
                            <span className="fa-li">
                              <i className="fas fa-times"></i>
                            </span>
                            Monthly Status Reports
                          </p>
                        </li>
                      </ul>
                      {/**************/}
                    </div>
                  </div>
                  <div className="card-footer bg-transparent border-top">
                    <div className="d-flex mb-0 team-social-links">
                      <div className="flex-fill">
                        <Link
                          to="#"
                          data-toggle="tooltip"
                          // className="btn btn-block btn-primary text-uppercase "
                          className="btn  btn-dark  btn-primary btn-block "
                          style={{ color: "white" }}
                          onClick={this.togglemodal_contactUs}
                        >
                          Contact Us
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
  render() {
    return (
      <React.Fragment>
        {this.render_sign_up_modal()}
        {this.render_sign_up_modal_contactus()}

        <section
          style={{ background: "#FFF" }}
          id="pricing"
          className="section  text-center"
        >
          <Container
            //fluid="lg" className="container_padding"

            id="container_padding"
            fluid={true}
          >
            <Row className="align-items-center">
              <Col lg="12">
                <div
                  className="text-center"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Col lg="8" sm="12">
                    <h1 className="section-heading section-header-size-color">
                      Let us help you understand your customers
                    </h1>
                    <hr className="black my-4 color_hr" />

                    <p className="top_p">
                      Send your customers short, pre-built and optimized surveys
                      through various channels, and see their feedback in
                      real-time on a live dashboard. Surveys can be displayed
                      in-store via QR codes, added on receipts via a short-link,
                      emailed
                    </p>
                    <div className="button-primary-middle">
                      <form
                        onSubmit={(event) => {
                          event.preventDefault();

                          this.togglemodal_contactUs();

                          //

                          /*
                          
                          
                            onClick={() => {
                            this.setState({
                              plan_selected: "MidRange",
                            });
                            this.togglemodal();
                          }}
                          this.setState({
                            plan_selected: "Starter",
                          });
                          this.togglemodal();*/
                        }}
                      >
                        <input
                          type="submit"
                          // value="Contact us"
                          value="Get Free Trial"
                          className="button-primary-middle "
                        />
                      </form>
                    </div>
                  </Col>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/*
          <section
            className="section"
            id="pricing"
            style={{ background: "#f3f5fe" }}
          >
            <Container>
              <Row>
                <Col lg="12">
                  <div className="text-center mb-5">
                    <h2>Pricing</h2>
                  </div>
                </Col>
              </Row>

              <Col lg="12">{this.render_pricing()}</Col>
            </Container>
          </section>
       */}
      </React.Fragment>
    );
  }
}

export default OurTeam;

/*


  <TabPane tabId={2} id="personal-info" style={{ padding: 0 }}>
                  <Form>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <Label for="kycfirstname-input">First name</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="kycfirstname-input"
                            placeholder="Enter First name"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <Label for="kyclastname-input">Last name</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="kyclastname-input"
                            placeholder="Enter Last name"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <Label for="kycphoneno-input">Phone</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="kycphoneno-input"
                            placeholder="Enter Phone number"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <Label for="kycbirthdate-input">Date of birth</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="kycbirthdate-input"
                            placeholder="Enter Date of birth"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <Label for="kycselectcity-input">City</Label>
                          <select
                            className="custom-select"
                            id="kycselectcity-input"
                          >
                            <option value="1" defaultValue>
                              San Francisco
                            </option>
                            <option value="2">Los Angeles</option>
                            <option value="3">San Diego</option>
                          </select>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </TabPane>
                
                */
