import React, { Component } from "react";
//import { Container, Row, Col } from "reactstrap";

import "react-responsive-carousel/lib/styles/carousel.min.css";
/*import "./css/luup.css";*/
//import "../.././CSS/luup.css";
import Navbar from ".././Navbar/Navbar";
import Footer from ".././Footer/footer";

class Features extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pos: document.documentElement.scrollTop,
      imglight: true,
      navClass: "",
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > this.state.pos) {
      this.setState({ navClass: "nav-sticky", imglight: false });
    } else {
      this.setState({ navClass: "", imglight: true });
    }
  };
  render() {
    return (
      <div>
        <Navbar navClass={this.state.navClass} imglight={this.state.imglight} />

        <section className="bg-white" style={{paddingTop:100}}>
          <div className="container ">
            <div className="row container_padding">
              <div className="col-lg-12 mx-auto">
                <h1 className="section-header text-center">TERMS AND CONDITIONS</h1>
                <hr className="black my-4 color_hr" />
              </div>
            </div>
          </div>
          

          <div className="container" style={{ paddingTop: 20 }}>
            <div className="row container_padding">
              <div className="col-lg-12 mx-auto">
                <div className="col-lg-10 mx-auto">
                  <p>
                    Hello, and welcome to Luup’s Terms and Conditions of Use!
                    <br />
                    Luup, a product of Eatonomy Corp., allows users the ability
                    to provide their feedback, insight, and reviews directly to
                    the physical businesses they interact with. In return, users
                    have the ability to earn points, perks, and rewards through
                    the Luup app, sites, and various applications by Eatonomy.
                    These are subject to the following Terms and Conditions.
                    <br />
                    <br />
                    These Terms and Conditions of Use (“Terms”, “Agreement”)
                    govern and apply to all applications and services offered by
                    Eatonomy Corp. (“Eatonomy,” “Luup”, “we,” “us,” and/or
                    “our”), including, but not limited to our websites, mobile
                    applications, surveys, participation in any surveys/studies,
                    as well as the eligibility for, and/or the redemption of any
                    incentives and rewards (“Application”, “Services”,
                    “Product”, “Platform”. By signing up or otherwise using the
                    Eatonomy services, websites, mobile applications, and
                    software applications ( “Service” or “Platform”), or
                    accessing any content or material that is made available by
                    Eatonomy through the Service (the “Content”) you are
                    entering into a binding contract with the Eatonomy entity
                    indicated at the bottom of this document. These Terms and
                    Conditions affect your legal rights, so please read them,
                    our Privacy Policy, and other terms referenced in this
                    document carefully.
                    <br />
                    <br />
                    Your agreement with us includes these Terms and Conditions
                    of Use and our Privacy Policy. If you wish to review the
                    terms of the Agreements, the effective version of the
                    Agreements can be found on our websites. You acknowledge
                    that you have read and understood the Agreements, accept
                    these Agreements, and agree to be bound by them. If you
                    don’t agree with (or cannot comply with) the Agreements,
                    then you may not use Eatonomy’s Services.
                    <br />
                    <br />
                    On each of your visits to the Platform, you hereby agree to
                    comply with this Agreement, which applies to all services
                    and applications available from Eatonomy. Thus, you
                    unconditionally agree to the Agreement in its entirety prior
                    to any use of any Eatonomy Service. The simple use of any
                    Eatonomy service automatically entails your acceptance of
                    the Agreement. We reserve the right to modify this Agreement
                    at any time and without prior notice.
                    <br />
                    <br />
                    We reserve the right, at our sole discretion, to change
                    and/or modify portions of these Terms and Conditions, at any
                    time, without prior notice. The date of revision will be
                    indicated at the bottom of this page. If you do not agree to
                    abide by these/ any future Terms, please stop using and
                    accessing the Service(s) provided by Eatonomy Corp. You are
                    advised to regularly refer to the latest version of the
                    Agreement, available on Eatonomy’s mobile platforms and
                    websites.
                  </p>
                  <h3>CONDITIONS OF USE</h3>
                  <p>
                    Eatonomy’s Services and/or Platforms may only be used for
                    lawful purposes. In order to use Luup’s Services, you will
                    need to download the Luup Mobile Application compatible with
                    your phone. You understand that due to technical reasons,
                    Luup may stop supporting a particular mobile operating
                    system, in which case you will need to update to a
                    compatible operating system to continue using the Platform.
                    You also understand and agree that the use of the Service
                    depends on your device and Internet connection functioning
                    properly, as well as your acceptance to being geo-located by
                    the mobile platform in order to provide you with relevant
                    offers in your area.
                    <br />
                    <br />
                    Eatonomy grants you the personal, non-commercial,
                    non-transferable, non-exclusive, revocable and limited right
                    to access and use the Service for your own personal purposes
                    as an individual customer. You may use the
                    Applications/Services only when and as available. Eatonomy
                    reserves the right to change, modify or eliminate, and/or
                    restrict or block access to, all or any part of the
                    Applications/Services, without notice, at any time, for any
                    reason.
                    <br />
                    <br />
                    As a condition to your use of the Service, you agree{" "}
                    <strong>not to:</strong>
                  </p>
                  <ul className="square">
                    <li>
                      permit any third party to use or access the Service;
                    </li>
                    <li>
                      access, tamper with, or use any non-public areas of the
                      Service and/or computer systems;
                    </li>
                    <li>
                      provide payment information belonging to a third party;
                    </li>
                    <li>
                      use spiders, robots or other automated data mining
                      techniques to collect, download, store or otherwise
                      reproduce or distribute data/content available in
                      connection with the Applications and/or Services, or to
                      manipulate the results of any survey, prize draw or
                      contest;
                    </li>
                    <li>
                      use the Service in an abusive way contrary to its intended
                      use, its documentation or to its reasonable instructions
                    </li>
                    <li>
                      modify and / or divert the legitimate utility of the
                      Mobile App or Service;
                    </li>
                    <li>
                      attempt to decipher, decompile, disassemble, or reverse
                      engineer any of the software used to provide the Service;
                    </li>
                    <li>
                      attempt to probe, scan, or test the vulnerability of the
                      Service or any related system or network or breach any
                      security or authentication measures used in connection
                      with the Service and such systems and networks;
                    </li>
                    <li>
                      harm or threaten to harm other users in any way or
                      interfere with, or attempt to interfere with: the access
                      of any user, host, network, including without limitation,
                      by sending a virus, overloading, flooding, spamming, or
                      mail-bombing the Service;
                    </li>
                    <li>
                      collect any personally identifiable information of or
                      about any other user of the Applications and/or Services
                    </li>
                    <li>
                      systematically retrieve data or other content from the
                      Service to create or compile, directly or indirectly, in
                      single or multiple downloads, a collection, compilation,
                      database, directory or the like, whether by manual
                      methods, through the use of bots, crawlers, or spiders, or
                      otherwise;
                    </li>
                    <li>
                      engage in any fraudulent activity, including, but not
                      limited to, speeding through surveys, taking the same
                      survey more than once, masking or forging your identity,
                      submitting false information during the registration
                      process, submitting false or untrue survey data, redeeming
                      or attempting to redeem rewards, prizes and/or incentives
                      through false or fraudulent means, and tampering with
                      surveys;
                    </li>
                    <li>
                      {" "}
                      post or transmit any threatening, libellous, defamatory,
                      obscene, pornographic, lewd, scandalous or inflammatory
                      material or content or any material or content that could
                      otherwise violate Applicable Laws
                    </li>
                    <li>
                      infringe third party intellectual property rights when
                      using or accessing the Service.
                    </li>
                  </ul>
                  <p>
                    Eatonomy has the right to investigate and prosecute security
                    breaches, copyright infringements, and violations of any of
                    the above conditions, to the fullest extent of the law.{" "}
                  </p>
                  <h3>THE LUUP PLATFORM </h3>
                  <p>
                    The Luup real-time feedback platform was created to enable
                    physical businesses to better connect with their tech-savvy
                    customers and understand their unique needs and wants. The
                    platform collects direct feedback from customers immediately
                    at the point of experience. In return for their time and
                    feedback, shoppers earn a variety of points, rewards,
                    incentives, and/or experiential rewards.
                  </p>
                  <h3>ACCOUNT</h3>
                  <p>
                    In order to use certain services, you may be required to
                    register for an account and provide certain information
                    about yourself in the registration form. You warrant that
                    all the information you submit is accurate and truthful, and
                    that you will maintain the accuracy of the information.
                    <br />
                    <br />
                    As the account holder, you are responsible for maintaining
                    the confidentiality of your account login information, and
                    are fully responsible for all activities that occur under
                    the account.
                    <br />
                    <br />
                    You agree to immediately inform Eatonomy of any unauthorized
                    use, or suspected unauthorized use of your account, and/or
                    any other breach of security. We cannot and will not be
                    liable for any loss or damage arising from your failure to
                    comply with the above requirements.
                    <br />
                    <br />
                    Eatonomy reserves the right to restrict or prohibit your use
                    of, access to and/or participation in the applications
                    and/or services if you provide, or Eatonomy reasonably
                    suspects that you have provided, information that is untrue,
                    inaccurate, not current, or incomplete.
                    <br />
                    <br />
                    Eatonomy may terminate your account and delete any content
                    contained in it if there is no account activity for over 12
                    months. We will attempt to warn you by email before
                    terminating your account to provide you with an opportunity
                    to log in to your account so that it remains active.
                  </p>
                  <h3>MOBILE SERVICES</h3>
                  <p>
                    Luup includes services that are available via a mobile
                    device, including the ability to upload content to the
                    platform, and the ability to browse and otherwise access the
                    service/ certain features thereof. To the extent you access
                    the Service through a mobile device, your wireless service
                    carrier’s standard charges, data rates and other fees may
                    apply. By using the Mobile Services, you agree that we and
                    Rewards Providers may communicate with you by automated SMS,
                    MMS, text message or other electronic means to your mobile
                    device and that information about your usage of the Mobile
                    Services may be communicated to us. In the event you change
                    or deactivate your mobile telephone number, you agree to
                    promptly update your Luup account information.
                  </p>
                  <h3>PRIVACY</h3>
                  <p>
                    During your use of Luup or other Eatonomy Services, you may
                    submit content to us. We understand that by giving us your
                    content, you trust us to treat it appropriately. Our privacy
                    policy details how we treat your content, and we agree to
                    adhere to those policies. You in turn agree that we may use
                    and share your content in accordance with our agreements,
                    privacy policies and applicable laws. You content is not
                    regarded as confidential if i) it is, or becomes public
                    (other than through breach of these terms by Eatonomy), ii)
                    it was lawfully known to Eatonomy before being received from
                    you, (iii) is received from a third party without knowledge
                    of breach of any obligation owed to you, or (iv) was
                    developed independently by Eatonomy without reference to
                    your content. You acknowledge and agree that Eatonomy may
                    disclose your content if and when required by law or legal
                    process.
                  </p>
                  <h3>DISCLAIMER AND LIMITATION OF LIABILITY</h3>
                  <p>
                    TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, YOU
                    ACKNOWLEDGE AND AGREE THAT IN NO EVENT SHALL EATONOMY, ITS
                    EMPLOYEES, AGENTS, OFFICERS, DIRECTORS, LICENSORS AND ITS
                    SUPPLIERS OR LICENSORS HAVE ANY LIABILITY, WHETHER BASED IN
                    CONTRACT, DELICT OR TORT (INCLUDING NEGLIGENCE) OR STRICT
                    LIABILITY, FOR INCIDENTAL, INDIRECT, CONSEQUENTIAL, SPECIAL,
                    OR PUNITIVE DAMAGES OF ANY KIND, OR FOR LOSS OF REVENUE OR
                    PROFITS, LOSS OF BUSINESS OR GOODWILL, LOSS OR CORRUPTION
                    OF, OR UNAUTHORIZED ACCESS TO, OR DISCLOSURE OF INFORMATION
                    OR DATA OR OTHER FINANCIAL LOSS ARISING OUT OF OR IN
                    CONNECTION WITH THE USE, PERFORMANCE, FAILURE, OR
                    INTERRUPTION OF THE EATONOMY SERVICE, WHETHER FORESEEABLE OR
                    NOT, AND EVEN IF EATONOMY HAD BEEN ADVISED OF THE
                    POSSIBILITY OF SUCH DAMAGES. EATONOMY DOES NOT MAKE ANY
                    REPRESENTATIONS OR WARRANTIES OF ANY KIND IN CONNECTION WITH
                    ANY SERVICES, PRODUCT, CONTENT, INFORMATION, MATERIAL, OR
                    SURVEYS, MADE AVAILABLE ON OR THROUGH THE APPLICATIONS AND
                    SERVICES, INCLUDING, BUT NOT LIMITED TO, ANY USER CONTENT.
                    EATONOMY DISCLAIMS ANY AND ALL WARRANTIES, WHETHER EXPRESS
                    OR IMPLIED.
                    <br />
                    <br />
                    TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, EATONOMY
                    MAKES NO WARRANTY OR REPRESENTATION REGARDING THE SERVICE,
                    INCLUDING THAT THE SERVICE WILL MEET YOUR REQUIREMENTS OR
                    WILL WORK IN COMBINATION WITH ANY HARDWARE OR SOFTWARE
                    PROVIDED BY THIRD PARTIES, THAT THE SERVICE WILL BE
                    UNINTERRUPTED, WITHOUT PROBLEMS OR ERROR FREE, OR THAT ALL
                    ERRORS IN THE SERVICE WILL BE CORRECTED.
                    <br />
                    <br />
                    EATONOMY PROVIDES THE APPLICATIONS, SERVICES, ALL
                    INFORMATION, SURVEYS, CONTENT, MATERIAL AND APPLICATIONS AND
                    SERVICES MADE AVAILABLE ON OR THROUGH THE EATONOMY
                    SERVICES,“AS IS” AND “AS AVAILABLE”. EATONOMY IS NOT AND
                    SHALL NOT BE RESPONSIBLE OR LIABLE FOR THE ACTIONS OR
                    OMISSIONS OF THIRD PARTIES THAT INTERFERE WITH, LIMIT,
                    RESTRICT OR PREVENT ACCESS OR CONNECTION TO, OR USE OF, THE
                    APPLICATIONS AND/OR SERVICES.
                    <br />
                    <br />
                    IN NO EVENT SHALL THE TOTAL AND AGGREGATE LIABILITY OF
                    EATONOMY AND ITS EMPLOYEES, AGENTS, OFFICERS, DIRECTORS,
                    LICENSORS, AND THEIR RESPECTIVE SUCCESSORS AND ASSIGNS TO
                    YOU FOR ANY AND ALL CLAIMS RELATING TO YOUR USE, MISUSE,
                    INABILITY TO USE, OR RELIANCE ON EATONOMY’S SERVICES OR ANY
                    CONTENT THEREIN EXCEED ONE HUNDRED CANADIAN DOLLARS ($100
                    CDN). ANY CLAIM OR CAUSE OF ACTION ARISING OUT OF OR RELATED
                    TO USE OF THE APPLICATIONS AND SERVICES AND/OR THESE TERMS
                    MUST BE FILED WITHIN ONE (1) YEAR AFTER SUCH CLAIM OR CAUSE
                    OF ACTION AROSE.
                    <br />
                    <br />
                    Although we will make all reasonable efforts to provide
                    accurate information on the Eatonomy platform, we cannot,
                    and do not, guarantee, represent, or warrant the accuracy,
                    completeness, timeliness, validation, stability and
                    availability of our services. This includes any materials,
                    graphics, text, links, descriptions, or other items in the
                    content contained within our services. All warranties and
                    representations are hereby excluded to the fullest extent
                    permitted by law.
                    <br />
                    <br />
                    Eatonomy is not liable or responsible for any failure to
                    perform or any delay in the performance of any obligations
                    relating to the Eatonomy Products or Services. Eatonomy is
                    also not liable or responsible for any failure or
                    non-compliance relating to its Services if such failure is
                    caused by circumstances outside of Eatonomy’s control. Such
                    circumstances may include but are not limited to:
                    disruptions in the operation of Eatonomy as a result of
                    legislation, acts of state or public authorities, acts of
                    war, terrorism, strikes, physical blockades, lockouts and
                    natural disasters.
                    <br />
                    <br />
                    We do not guarantee, represent, or warrant that your use of
                    our services will be uninterrupted or error-free, that any
                    defects or errors will be corrected or that our services
                    will be free from loss, corruption, attack, viruses,
                    interference, hacking, or other harmful components or
                    security intrusions, and we disclaim any liability relating
                    thereto.
                    <br />
                    <br />
                    USE OF OUR SERVICES IS SOLELY AT YOUR OWN RISK. TO THE
                    FULLEST EXTENT PERMITTED BY LAW, WE HEREBY DISCLAIM ALL
                    LIABILITY (HOWSOEVER ARISING) IN CONNECTION WITH ANY LOSS OR
                    DAMAGE, ARISING OUT OF OR IN CONNECTION WITH ACCESS TO, THE
                    USE OF, OR THE INABILITY TO USE, ALL OR ANY PART OF OUR
                    SERVICES OR THE CONTENT THEREIN, OR ANY ACTION TAKEN (OR
                    REFRAINED FROM BEING TAKEN) AS A RESULT OF USING ANY OF THE
                    FOREGOING.
                  </p>
                  <h3>INDEMNIFICATION</h3>
                  <p>
                    You agree to defend, indemnify, and hold harmless Eatonomy
                    and its affiliated and subsidiary companies as well as its
                    members, employees, managers, shareholders, officers,
                    directors, affiliates, suppliers, agents, and their
                    respective successors from and against any and all claims,
                    losses, complaints, damages, suits, charges, costs,
                    liabilities, and expenses, including legal fees and
                    disbursements, resulting from, arising from, or relating to,
                    either directly or indirectly by:
                    <br />
                    <br />
                    1. your use of, misuse of, participation in, access to, or
                    reliance upon, our Services
                    <br />
                    2. your breach/ violation of these Terms.
                    <br />
                    <br />
                    Eatonomy reserves the right, if it chooses, to assume the
                    exclusive defense and control of any matter that you are
                    required to indemnify at your expense. You agree to
                    cooperate with Eatonomy’s defense of any such claim,
                    including foregoing acceptance of any offers to settle and
                    refraining from making any such offers without the consent
                    of Eatonomy. We will make a reasonable effort to notify you
                    of any claim that is captured by this section.
                  </p>
                  <h3>AMENDMENTS</h3>
                  Eatonomy has the right to amend this Agreement, and any clause
                  within, at any time, in whole or in part. Eatonomy will make
                  commercially reasonable efforts to give notice of such
                  amendments before they come into force. You may refuse such
                  amendments by ceasing to access or use the Eatonomy
                  Service(s). Your refusal of an amendment results in the
                  termination of this Agreement, the automatic deactivation of
                  your account and denial of access to the Eatonomy Service.
                  <h3>GOVERNING LAW</h3>
                  <p>
                    This Agreement shall be governed by and construed by the
                    laws of the Province of Ontario, Canada, excluding any body
                    of law governing conflicts of laws. Parties hereby waive any
                    right to a trial by jury with respect to any lawsuit or
                    judicial proceeding arising or relating to this Agreement.
                    If the courts in your jurisdiction will not permit you to
                    consent to the jurisdiction and venue of Ontario, Canada,
                    then your local jurisdiction and venue will apply to any
                    disputes arising out of or related to this Agreement.
                  </p>
                  <h3>NON-ASSIGNMENT</h3>
                  <p>
                    You shall not assign or otherwise transfer this Agreement or
                    any of its rights or obligations hereunder to any third
                    party without the prior written consent of Eatonomy. No
                    assignment or delegation by you shall relieve or release you
                    from any of its obligations under this Agreement. Subject to
                    the foregoing, this Agreement shall be binding upon, inure
                    to the benefit of, and be enforceable by each of the Parties
                    and their respective successors and assigns. Eatonomy may
                    assign, transfer, or delegate any of its rights and
                    obligations hereunder without your consent.
                  </p>
                  <h3>GENERAL</h3>
                  <p>
                    Without limiting the generality of the preceding, Eatonomy
                    shall not be liable for the failure to execute all or part
                    of its obligations, in any way whatsoever, towards you or
                    your business, nor for damages or losses that you may have
                    incurred, if the failure, damages or losses result from an
                    event of force majeure or from circumstances beyond one’s
                    control. If a particular provision of this Agreement is held
                    to be invalid by a court of competent jurisdiction, the
                    provision shall be deemed severed from this Agreement and
                    shall not affect the validity of this Agreement as a whole.
                    Eatonomy has the right to investigate and prosecute
                    violations of any of the above, including without
                    limitation, possible infringement of any intellectual
                    property rights and possible security breaches, to the
                    fullest extent of the law. Eatonomy may involve and
                    cooperate with law enforcement authorities in prosecuting
                    users who violate this Agreement. You acknowledge that
                    although Eatonomy has no obligation to monitor your access
                    to or use of the Eatonomy Service, it has the right to do so
                    for the purpose of operating the Platform,, to ensure your
                    compliance with this Agreement, or to comply with applicable
                    law or the order or requirement of a court, administrative
                    agency, or other governmental body. The headings contained
                    in these Terms are for reference only and shall have no
                    effect on the interpretation and/or application of these
                    Terms. Eatonomy’s failure to enforce a breach by you of
                    these Terms shall not waive or release you from such breach
                    and shall not prevent, waive, or release Eatonomy from
                    enforcing any subsequent breach by you of these Terms.
                    <br />
                    <br />
                    All trademarks, logos and service marks displayed on the
                    Site are our property or the property of other third
                    parties. You are not permitted to use these marks without
                    our prior written consent or the consent of such third party
                    which may own the marks.
                    <br />
                    <br />
                    This Agreement is the entire, final and exclusive agreement
                    between you and Eatonomy. Any prior agreement you may have
                    had with Eatonomy is superseded by these Terms.
                  </p>
                  <h3>Contacting Us</h3>
                  <p>
                    If you have any questions, comments or feedback regarding
                    this Agreement, you may contact us at support@getluup.com
                  </p>
                  <p>
                    Eatonomy Corporation
                    <br />
                    20 Dundas St. W<br />
                    Toronto, Ontario M5G2C2
                    <br />
                    Canada
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section style={{ paddingTop: 0 }} className="bg-white">
          <div className="container">
            <div className="row container_padding">
              <div className="col-lg-12 mx-auto text-center">
                <h1 className="section-header text-center">PRIVACY POLICY</h1>
                <hr className="black my-4 color_hr" />
              </div>
            </div>
          </div>

          <div className="container" style={{ paddingTop: 20 }}>
            <div className="row container_padding">
              <div className="col-lg-12 mx-auto">
                <div className="col-lg-10 mx-auto">
                  <h3>Introduction</h3>
                  <p>
                    We respect the privacy and the integrity of any information
                    that you provide in the use of our websites, products, and
                    services. This Privacy Policy applies to Luup, and all other
                    websites, products, and services offered by Eatonomy Corp.
                    It explains our policy regarding the collection, use, and
                    disclosure of your personal information during your use of
                    Luup, and all of software, mobile applications, websites
                    and/or services offered by Eatonomy Corp. It also describes
                    how we collect, use and disclose your personal information
                    and how this personal information can be consulted and
                    corrected when necessary. This policy also explains how
                    cookies and/or log files are used on our Website and/or
                    mobile application. By visiting our websites, apps, agreeing
                    to receive emails from us, or contacting us, you accept the
                    terms and conditions outlined in this policy. This policy
                    does not extend to websites and/or mobile applications
                    operated by third parties, therefore, Eatonomy is not liable
                    for their privacy policies, procedures and practices.
                  </p>
                  <h3>Information we collect</h3>
                  <p>
                    Luup does not collect any personally identifiable
                    information about you when you visit the Website unless you
                    voluntarily provide this information, for example by
                    contacting us through our email forms. By providing
                    information through our email forms you consent to its use
                    to respond to your requests, to contact you and to provide
                    information about Luup’s services.
                  </p>
                  <ul className="square">
                    <li>
                      <span style={{ fontWeight: "bold" }}>
                        Information that you provide to us
                      </span>
                      <br />
                      An example of contact information is an email address. You
                      may also provide us with your name, phone number,
                      username, password or any other information that you
                      directly give us on our website, app, or as a response to
                      Luup’s surveys.
                      <br />
                      <br />
                    </li>
                    <li>
                      <span style={{ fontWeight: "bold" }}>
                        Usage information
                      </span>
                      <br />
                      When you interact with our websites and services, we
                      automatically collect usage information about your
                      interaction with us; for example, the webpages you viewed,
                      where you visited our site from, or what your language
                      preference is.
                      <br />
                      <br />
                    </li>
                    <li>
                      <span style={{ fontWeight: "bold" }}>
                        Device and browser data
                      </span>
                      <br />
                      When you access or products or services, we may collect
                      information from the device and application you use. This
                      may include data like the browser you used, the operating
                      system version, the type of device you used, the device IP
                      address, device ID/MAC address, or the UUID for a mobile
                      device.
                      <br />
                      <br />
                    </li>
                    <li>
                      <span style={{ fontWeight: "bold" }}>Cookies</span>
                      <br />
                      As is standard for most other sites, Luup products may
                      also collect cookies. These cookies cannot be used to
                      extract information, and may only be used to optimize your
                      experience on Luup’s website and/or mobile platforms. You
                      can always configure your browser to disable cookies. We
                      do not use cookies for tracking purposes. You can choose
                      to have your computer warn you each time a cookie is being
                      sent, or you can choose to turn off all cookies. You do
                      this through your browser settings. Since browser is a
                      little different, look at your browser's Help Menu to
                      learn the correct way to modify your cookies. If you turn
                      cookies off, some of the features that make your site
                      experience more efficient may not function properly.
                      <br />
                      <br />
                    </li>
                  </ul>
                  <h3>Use of Collected Information</h3>
                  <p>
                    We may use the information we collect from you, in the
                    following ways:
                  </p>
                  <ul className="square">
                    <li>
                      To allow Luup to provide you with its services and to
                      process and fulfill requests
                    </li>
                    <li>
                      To improve, operate, and maintain our products, services,
                      and websites
                    </li>
                    <li>
                      To troubleshoot and resolve any issues including those
                      related to account activation/deactivation , support,
                      maintenance, and to respond to your inquiries
                    </li>
                    <li>
                      To personalize your experience and to allow us to deliver
                      the type of content and product offerings in which you are
                      most interested
                    </li>
                    <li>
                      To provide customer service and ask for ratings/reviews of
                      services or products
                    </li>
                    <li>
                      To communicate, update, and inform you about any new or
                      existing offers related to product(s) offered by us or our
                      partners, promotions, upcoming events and for any other
                      purposes for which you have provided consent
                    </li>
                    <li>
                      To investigate, mitigate and/or resolve any technical and
                      security issues, and to protect and deter against illegal,
                      unauthorized and/or fraudulent activity
                    </li>
                    <li>
                      For purposes required and/or authorized by law and for
                      responding to legal requests, including ensuring our legal
                      rights are being protected.
                    </li>
                  </ul>

                  <h3>Sharing of Personal Information</h3>
                  <p>
                    Only if you have provided consent, we may share information
                    with others, who, for example, could use it for their own
                    research or marketing uses. Non-personally identifiable
                    (anonymized and/or aggregated) visitor information may be
                    provided to other parties for marketing, advertising, or
                    other uses. We may also have to share information in order
                    to:
                  </p>
                  <ul className="square">
                    <li>
                      Comply with applicable regulations, laws, legal processes,
                      or enforceable governmental requests
                    </li>
                    <li>
                      Detect, investigate and prevent security, fraud, or
                      technical issues
                    </li>
                    <li>
                      Protect the rights and property of our users, agents,
                      customers, the public, Eatonomy Corp., and others. This
                      includes enforcing applicable policies, and/or
                      investigating potential violations.
                    </li>
                    <li>
                      Facilitate a sale, merger or change in control of all or
                      any part of the company, or in preparation for any of
                      these events
                    </li>
                  </ul>

                  <h3>Children’s Online Privacy Protection</h3>
                  <p>
                    The Luup Website and App do not knowingly collect any
                    personal information from children under the age of 13. In
                    the case that we become aware that we have unknowingly
                    collected personal information from a child under the age of
                    13, we will ensure that we make commercially reasonable
                    efforts to delete such personal information from our
                    database.
                  </p>

                  <h3>Third-party links</h3>
                  <p>
                    Occasionally, at our discretion, we may include or offer
                    third-party products or services on our website. These
                    third-party sites have separate and independent privacy
                    policies. We therefore have no responsibility or liability
                    for the content and activities of these linked sites.
                    Nonetheless, we seek to protect the integrity of our site
                    and welcome any feedback about these sites.
                  </p>
                  <h3>Your Right to Access and Modify Information</h3>
                  <p>
                    You can contact us at any time to stop receiving emails from
                    us, to modify your consent for information use and
                    disclosure, to review and/or modify the information we have
                    related to your account, or to close your account; by
                    emailing us at support@getluup.com, or by writing to us at
                    20 Dundas St. W, Suite 921, Toronto, ON M5G2C2. All requests
                    to access and rectify must be accompanied with satisfactory
                    proof of identity. To unsubscribe from receiving promotional
                    emails from us, click the ‘unsubscribe’ button found at the
                    bottom of each email.
                  </p>

                  <h3>Contacting Us</h3>
                  <p>
                    We welcome your questions, comments or feedback regarding
                    this privacy policy. You may contact us using the
                    information below.
                  </p>
                  <p>
                    Eatonomy Corporation
                    <br />
                    20 Dundas St. W<br />
                    Toronto, Ontario M5G2C2
                    <br />
                    Canada
                  </p>

                  <h3>Policy Updates</h3>
                  <p>
                    In the event that this Policy changes in the future, an
                    updated version will be uploaded on our website. This
                    Privacy Policy may be amended from time to time without
                    notice. If we make any changes to this Privacy Policy, we
                    will change the Last Updated date below.
                  </p>

                  <p style={{ fontWeight: "bold" }}>
                    Last Updated: February 7, 2019
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    );
  }
}

export default Features;
