import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Import images
import first from "../../../../assets/images/crypto/features-img/img-1.png";
import second from "../../../../assets/images/crypto/features-img/img-2.png";
import Lottie from "react-lottie";

class Features extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <section
          className="section"
          style={{
            background: "#f3f5fe",
          }}
          id="features"
        >
          <Container id="container_padding" fluid={true}>
            <Row className="align-items-center pt-4">
              <Col md="12" lg="6" sm="12" className="ml-auto">
                <div>
                  {/*<img
                    src={first}
                    alt=""
                    className="img-fluid mx-auto d-block"
                  />*/}
                  <div>
                    <div>
                      <Lottie
                        options={{
                          loop: true,
                          autoplay: true,
                          animationData: require("./JSON/customer-experience-five-stars.json"),
                          rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice",
                          },
                        }}
                        width="60%"
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col md="12" lg="5" sm="12" className="ml-auto">
                <div className="mb-5">
                  <h1 className="section-heading section-header">
                    Engage disengaged students
                  </h1>
                  <hr className="black my-4 underline" />
                  <p className="top_p center_text">
                    Do you always find the same people participating in your
                    classroom? With Luup, provide each student with the
                    opportunity to share their feedback and ask questions, in a
                    no-pressure, non-judgemental environment.
                  </p>
                </div>
              </Col>
            </Row>

            <Row
              className="align-items-center mt-5 pt-md-5"
              style={{ paddingTop: 0, paddingBottom: 0 }}
            >
              <Col md="12" lg="5" sm="12" className="ml-auto">
                <div className="mb-5">
                  <h1 className="section-heading section-header">
                    Simple, quick, and easy. No downloads required.
                  </h1>
                  <hr className="black my-4 underline" />

                  <p className="top_p center_text">
                    Whenever you want to gather feedback in real-time, put up a
                    simple QR code on the screen, embed it into a presentation
                    or send a short link on via a chat. Students can anonymously
                    and instantly provide their feedback while it’s still fresh,
                    and you can use that feedback to make each class better than
                    the last.
                  </p>
                </div>
              </Col>
              <Col md="12" lg="6" sm="12" className="ml-auto">
                <div className="mt-4 mr-md-0">
                  {/*} <img
                    src={second}
                    alt=""
                    className="img-fluid mx-auto d-block"
                  />*/}
                  <div>
                    <div>
                      <Lottie
                        speed={0.25}
                        options={{
                          loop: true,
                          autoplay: true,
                          animationData: require("./JSON/class.json"),
                          rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice",
                          },
                        }}
                        width="60%"
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="align-items-center pt-4">
              <Col md="12" lg="6" sm="12" className="ml-auto">
                <div>
                  {/*<img
                    src={first}
                    alt=""
                    className="img-fluid mx-auto d-block"
                  />*/}

                  <div>
                    <div>
                      <Lottie
                        speed={0.25}
                        options={{
                          loop: true,
                          autoplay: true,
                          animationData: require("./JSON/students.json"),
                          rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice",
                          },
                        }}
                        width="90%"
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col md="12" lg="5" sm="12" className="ml-auto">
                <div className="mb-5">
                  <h1 className="section-heading section-header">
                    Provide a learning playground
                  </h1>
                  <hr className="black my-4 underline" />
                  <p className="top_p">
                    Luup helps you provide what your students need to thrive
                  </p>
                  <div className="top_p">
                    <ul>
                      <li>
                        Make lessons more effective, and track the impact of
                        each lesson over time
                      </li>
                      <li>
                        Pinpoint and address learning gaps ahead of assessments
                      </li>
                      <li>
                        Build a strong and trusting relationship with your
                        students
                      </li>
                      <li>Keep a Pulse on student happiness </li>
                      <li>
                        Help students design their own learning experience{" "}
                      </li>
                      <li>
                        Let your students evaluate their own understanding
                      </li>
                      <li>Track the impact of each lesson over time</li>
                      <li>
                        Teach students to self-advocate and self-assess their
                        learning in a non-intimidating environment
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>

            {/* <Row
              className="align-items-center mt-5 pt-md-5"
              style={{ paddingTop: 0, paddingBottom: 0 }}
            >
              <Col md="12" lg="5" sm="12" className="ml-auto">
                <div className="mb-5">
                  <h1 className="section-heading section-header">
                    Encourage Promoters, Win over detractors
                  </h1>
                  <hr className="black my-4 underline" />
                  <p className="top_p">
                    Prompt your fans to leave a review on Google, and win back
                    detractors by addressing their concerns and showing how much
                    you value them
                  </p>
                </div>
              </Col>
              <Col md="12" lg="6" sm="12" className="ml-auto">
                <div className="mt-4 mr-md-0">
                  <div>
                    <div>
                      <Lottie
                        speed={0.25}
                        options={{
                          loop: true,
                          autoplay: true,
                          animationData: require("./JSON/dashboard.json"),
                          rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice",
                          },
                        }}
                        width="65%"
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>*/}
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Features;
