
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';

//i18n
import { withNamespaces } from 'react-i18next';

// users
{/*import user1 from '../../../assets/images/users/avatar-1.jpg';*/}

class ProfileMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            name: "Admin"
        };
        this.toggle = this.toggle.bind(this);
    }


    toggle() {
        this.setState(prevState => ({
            menu: !prevState.menu
        }));
    }

    componentDidMount()
    {
          if(localStorage.getItem("authUser"))
           {
             if(process.env.REACT_APP_DEFAULTAUTH === 'firebase')
             {
                const obj = JSON.parse(localStorage.getItem("authUser"));
                this.setState({name : obj.displayName});
             }
              else if((process.env.REACT_APP_DEFAULTAUTH === 'fake') || (process.env.REACT_APP_DEFAULTAUTH === 'jwt'))
             {
                const obj = JSON.parse(localStorage.getItem("authUser"));
                this.setState({name : obj.username});
             }
          }
    }
    
    render() {

        return (
            <React.Fragment>
                <div className="text-center">
                <Dropdown isOpen={this.state.menu} toggle={this.toggle} className="d-inline-block"  >
                    <DropdownToggle className="btn header-item waves-effect"  style={{padding:0}}id="page-header-user-dropdown" tag="button">
                        {/*<img className="rounded-circle header-profile-user" src={user1} alt="Header Avatar" />*/}
                        {/*<span className="d-none d-xl-inline-block ml-2 mr-1">{this.state.name}</span>*/}

                        <a to="/partner" className="btn btn-outline-dark w-xs">

                  Partner with Luup
                  <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>

                </a>



                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem tag="a" href="/enterprise">
                         {/*   <i className="bx bx-user font-size-16 align-middle mr-1"></i>*/}
                            {this.props.t('Enterprize')}
                        </DropdownItem>
                        <DropdownItem tag="a" href="/small-businesses">
                           {/* <i className="bx bx-wallet font-size-16 align-middle mr-1"></i>*/}
                            {this.props.t('Small Businesses')}
                        </DropdownItem>
                        <DropdownItem tag="a" href="/education">
                       {/* <i className="bx bx-wrench font-size-17 align-middle mr-1"></i>*/}
                        {this.props.t('Education')}
                        </DropdownItem>

             
                       {/* <DropdownItem tag="a" href="auth-lock-screen">
                            <i className="bx bx-lock-open font-size-16 align-middle mr-1"></i>{this.props.t('Lock screen')}
                        </DropdownItem>
                        <div className="dropdown-divider"></div>
                        <Link to="/logout" className="dropdown-item">
                            <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
                            <span>{this.props.t('Logout')}</span>
                        </Link>*/}
                    </DropdownMenu>
                </Dropdown>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(withNamespaces()(ProfileMenu));
