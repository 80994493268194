import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Import images
import first from "../../../../assets/images/crypto/features-img/img-1.png";
import second from "../../../../assets/images/crypto/features-img/img-2.png";

class Features extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <section
          className="section"
          style={{
            background: "#f3f5fe",
          }}
          id="features"
        >
          <Container id="container_padding" fluid={true}>
            <Row className="align-items-center pt-4">
              <Col md="12" lg="6" sm="12" className="ml-auto">
                <div>
                  <img
                    src={first}
                    alt=""
                    className="img-fluid mx-auto d-block"
                  />
                </div>
              </Col>
              <Col md="12" lg="5" sm="12" className="ml-auto">
                <div className="mb-5">
                  <h1 className="section-heading section-header">
                    Collect Actionable Insights
                  </h1>
                  <hr className="black my-4 underline" />
                  <p className="top_p center_text">
                    Automatically pinpoint the location, staff, and exact
                    moments that are resulting in the greatest dips in customer
                    satisfaction and NPS scores, so you can better train staff
                    and make improvements for a flawless customer experience
                  </p>
                </div>
              </Col>
            </Row>

            <Row
              className="align-items-center mt-5 pt-md-5"
              style={{ paddingTop: 0, paddingBottom: 0 }}
            >
              <Col md="12" lg="5" sm="12" className="ml-auto">
                <div className="mb-5">
                  <h1 className="section-heading section-header">
                    Run Ad-hoc Survey Campaigns
                  </h1>
                  <hr className="black my-4 underline" />

                  <p className="top_p center_text">
                    Quickly get answers to specific questions, from your own
                    customers. Run survey campaigns at one location, a select
                    few locations, or at all locations – to pinpoint issues,
                    hear customer thoughts on new product launches and ideas,
                    and identify opportunities to provide your customers with a
                    stellar experience.
                  </p>
                </div>
              </Col>
              <Col md="12" lg="6" sm="12" className="ml-auto">
                <div className="mt-4 mr-md-0">
                  <img
                    src={second}
                    alt=""
                    className="img-fluid mx-auto d-block"
                  />
                </div>
              </Col>
            </Row>

            <Row className="align-items-center pt-4">
              <Col md="12" lg="6" sm="12" className="ml-auto">
                <div>
                  <img
                    src={first}
                    alt=""
                    className="img-fluid mx-auto d-block"
                  />
                </div>
              </Col>
              <Col md="12" lg="5" sm="12" className="ml-auto">
                <div className="mb-5">
                  <h1 className="section-heading section-header">
                    See feedback in real-time
                  </h1>
                  <hr className="black my-4 underline" />
                  <p className="top_p center_text">
                    Feedback appears instantly on your dashboard, as soon as a
                    customer shares it. Uncover trends and insight compare with
                    other locations, and
                  </p>
                </div>
              </Col>
            </Row>

            <Row
              className="align-items-center mt-5 pt-md-5"
              style={{ paddingTop: 0, paddingBottom: 0 }}
            >
              <Col md="12" lg="5" sm="12" className="ml-auto">
                <div className="mb-5">
                  <h1 className="section-heading section-header">
                    Encourage Promoters, Win over detractors
                  </h1>
                  <hr className="black my-4 underline" />
                  <p className="top_p">
                    Prompt your fans to leave a review on Google, and win back
                    detractors by addressing their concerns and showing how much
                    you value them
                  </p>
                </div>
              </Col>
              <Col md="12" lg="6" sm="12" className="ml-auto">
                <div className="mt-4 mr-md-0">
                  <img
                    src={second}
                    alt=""
                    className="img-fluid mx-auto d-block"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Features;
