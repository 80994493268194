import React, { Component } from "react";
import { Col, Card, CardBody, Media } from "reactstrap";

class CardBox extends Component {
  render() {
    return (
      <React.Fragment>
        {this.props.coins.map((coin, key) => {
          return (
            <Col
              lg="3"
              md="6"
              sm="12"
              //md="3"

              key={key}
            >
              <Card>
                <CardBody>
                  <Media>
                    {/* <div className="avatar-xs mr-3">
                    <span
                      className={
                        "avatar-title rounded-circle bg-soft-" +
                        coin.color +
                        " text-" +
                        coin.color +
                        " font-size-18"
                      }
                    >
                      <i className={coin.icon}></i>
                    </span>
                  </div>*/}
                    <Media body>
                      <div className="text-center">
                        <div

                        //className="avatar-xs mr-3"
                        >
                          <span
                            // className={
                            //   "avatar-title rounded-circle bg-soft-" +
                            //   coin.color +
                            //   " text-" +
                            //   coin.color +
                            //   " font-size-18"
                            // }
                            style={{
                              fontSize: 50,
                            }}
                          >
                            <i className={coin.icon}></i>
                          </span>
                        </div>
                        <h3>{coin.title}</h3>
                        {/*<h5>{coin.value}</h5>*/}
                        <p className="text-muted mb-0 font-size-15">
                          {coin.value}
                        </p>
                        {/* <p className="text-muted text-truncate mb-0">{coin.isIncrease ? "+ " : "- "} {coin.rate} <i className={coin.isIncrease ? "mdi mdi-arrow-up ml-1 text-success" : "mdi mdi-arrow-down ml-1 text-danger"}></i></p>
                         */}
                      </div>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col>
          );
        })}
      </React.Fragment>
    );
  }
}

export default CardBox;
