import React, { Component } from "react";
import {
  Navbar,
  Nav,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  Container,
  Collapse,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import { Link } from "react-router-dom";
import ScrollspyNav from "./scrollSpy";

import ParttnerPages from "./ParttnerPages";

//Import Images
import logodark from "../../../../assets/images/logo-dark.png";
import logolight from "../../../../assets/images/logo-light.png";

class Navbar_Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navItems: [
        /*
        { id: 1, idnm: "about", navheading: "ABOUT" },
        { id: 2, idnm: "rewards", navheading: "REWARDS" },
        { id: 3, idnm: "howitworks", navheading: "HOW IT WORKS" }, 
        */
        { id: 1, idnm: "rewards", navheading: "REWARDS" },
        { id: 2, idnm: "howitworks", navheading: "HOW IT WORKS" },
      ],
      isOpenMenu: false,
    };
  }

  toggle = () => {
    this.setState({ isOpenMenu: !this.state.isOpenMenu });
  };

  render() {
    let TargetId = this.state.navItems.map((item) => {
      return item.idnm;
    });

    console.log(this.props.imglight);
    return (
      <React.Fragment>
        <Navbar
          expand="lg"
          fixed="top"
          className={"navigation sticky " + this.props.navClass}
          style={{ background: "white" }}
        >
          <Container>
            <NavbarBrand href="/">
              {this.props.imglight !== true ? (
                <img
                  src={logodark}
                  alt=""
                  height="40"
                  className="logo logo-dark"
                />
              ) : (
                <img
                  src={logolight}
                  alt=""
                  height="40"
                  className="logo logo-dark"
                />
              )}
            </NavbarBrand>

            <NavbarToggler className="p-0" onClick={this.toggle}>
              <i className="fa fa-fw fa-bars"></i>
            </NavbarToggler>

            <Collapse
              id="topnav-menu-content"
              isOpen={this.state.isOpenMenu}
              navbar
            >
              <ScrollspyNav
                scrollTargetIds={TargetId}
                scrollDuration="300"
                headerBackground="true"
                activeNavClass="active"
                className="navbar-collapse"
              >
                <Nav className="ml-auto navbar-nav" id="topnav-menu">
                  {this.state.navItems.map((item, key) => (
                    <NavItem
                      key={key}
                      className={item.navheading === "Home" ? "active" : ""}
                      // className="text-center"
                    >
                      <NavLink href={"#" + item.idnm}>
                        {" "}
                        {item.navheading}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
              </ScrollspyNav>
              <div className="ml-lg-2">
                {/* <ParttnerPages />*/}

                {/*<Link to="/partner" className="btn btn-outline-dark w-xs">
                  Partner with Luup
                </Link>*/}
              </div>
            </Collapse>
          </Container>
        </Navbar>
      </React.Fragment>
    );
  }
}

export default Navbar_Page;

/*import React, { Component } from "react";
import {
  Navbar,
  Nav,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  Container,
  Collapse,
} from "reactstrap";
import ScrollspyNav from "./scrollSpy";

//Import Images
import logodark from "../../../../assets/images/logo-dark.png";
import logolight from "../../../../assets/images/logo-light.png";

class Navbar_Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navItems: [
        { id: 1, idnm: "about", navheading: "About" },
        { id: 2, idnm: "features", navheading: "Features" },
      ],
      isOpenMenu: false,
    };
  }

  toggle = () => {
    this.setState({ isOpenMenu: !this.state.isOpenMenu });
  };
  render() {
    let TargetId = this.state.navItems.map((item) => {
      return item.idnm;
    });

    return (
      <React.Fragment>
        <Navbar
          expand="lg"
          fixed="top"
          className={"navigation sticky " + this.props.navClass}
        >
          <Container>
            <NavbarBrand href="/">
              {this.props.imglight !== true ? (
                <img
                  src={logodark}
                  alt=""
                  height="40"
                  className="logo logo-dark"
                />
              ) : (
                <img
                  src={logolight}
                  alt=""
                  height="40"
                  className="logo logo-dark"
                />
              )}
            </NavbarBrand>

            {this.props.imglight !== true ? (
              <NavbarToggler className="p-0" onClick={this.toggle}>
                <i className="fa fa-fw fa-bars"></i>
              </NavbarToggler>
            ) : (
              <NavbarToggler className="p-0" onClick={this.toggle}>
                <i className="fa fa-fw fa-bars" style={{ color: "white" }}></i>
              </NavbarToggler>
            )}
            <Collapse
              id="topnav-menu-content"
              isOpen={this.state.isOpenMenu}
              navbar
            >
              <ScrollspyNav
                scrollTargetIds={TargetId}
                scrollDuration="300"
                headerBackground="true"
                activeNavClass="active"
                className="navbar-collapse"
              >
                {this.props.imglight !== true ? (
                  <Nav className="ml-auto navbar-nav" id="topnav-menu">
                    {this.state.navItems.map((item, key) => (
                      <NavItem
                        key={key}
                        className={item.navheading === "Home" ? "active" : ""}
                      >
                        <NavLink href={"#" + item.idnm}>
                          {" "}
                          {item.navheading}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                ) : (
                  <Nav className="ml-auto navbar-nav-partner" id="topnav-menu">
                    {this.state.navItems.map((item, key) => (
                      <NavItem
                        key={key}
                        className={item.navheading === "Home" ? "active" : ""}
                      >
                        <NavLink
                          href={"#" + item.idnm}
                          style={{ color: "white" }}
                        >
                          {" "}
                          {item.navheading}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                )}
              </ScrollspyNav>
              <div className="ml-lg-2">
                <a
                  href="https://partner.getluup.com/"
                  className="btn btn-light w-xs"
                >
                  Sign in
                </a>
            
              </div>
            </Collapse>
          </Container>
        </Navbar>
      </React.Fragment>
    );
  }
}

export default Navbar_Page;

*/
