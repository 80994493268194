import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css";
//import "../.././CSS/subscribe.css";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

class Features extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      contactFormSubmittedResult: "",
    };
  }

  render_contactFormSubmittedResult() {
    if (this.state.contactFormSubmittedResult === "success") {
      return (
        <div>
          <div className="text-center suceesSubscriber">
            <p style={{ color: "#1B5E20" }}>Subscribed!</p>
          </div>
        </div>
      );
    } else if (this.state.contactFormSubmittedResult === "error") {
      return (
        <div>
          <div className="text-center errorSubscriber">
            <p style={{ color: "#B71C1C" }}>
              Error subscribing, please try again.
            </p>
          </div>
        </div>
      );
    }
    return null;
  }
  render() {
    /*  return (
      <section className="site-subscribe paddingTopBottom50">
        <div className="container">
          <div className="row container_padding">
            <div className="col-lg-8 mx-auto text-center ">
              <h2 className="subscriberTopHeading">
                Get in on all our Luupy updates!
              </h2>

              <br />
              <form
                className="site-form"
                id="emailAddressSubscribe"
                onSubmit={(event) => {
                  event.preventDefault();

                  var emailSubscribe = this.state.email;

                  firebase
                    .database()
                    .ref("Admin/Email_Subcribers/")
                    .push({
                      email: emailSubscribe,
                    })
                    .then(() => {
                      this.setState({
                        contactFormSubmittedResult: "success",
                      });
                    })
                    .catch((error) => {
                      console.log(error);
                      this.setState({ contactFormSubmittedResult: "error" });
                    });
                }}
              >
                <label>
                  <input
                    className="noOutline"
                    type="email"
                    id="emailSubscribe"
                    placeholder="Enter your email here"
                    required="required"
                    onChange={(event) => {
                      this.setState({ email: event.target.value });
                    }}
                  />
                </label>
                <input className="btn-subscribe" type="submit" value="SUBMIT" />
              </form>
              <div style={{ paddingTop: 10 }}>
                {this.render_contactFormSubmittedResult()}
                <div id="contactFormSubmittedResult"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );*/
    return (
      <React.Fragment>
        <section
          //className=" bg-white  zeroPaddingBottomTop"

          className=" bg-white"
          id="subscribe"
        >
          <Container
            id="container_padding"
            fluid={true}
            //fluid="lg" className="container_padding"
          >
            <Row className="align-items-center pt-4">
              <Col md="12" sm="12">
                <div className="site-subscribe paddingTopBottom50">
                  <div className="container">
                    <div className="row container_padding">
                      <div className="col-lg-8 mx-auto text-center ">
                        <h2 className="subscriberTopHeading">
                          Get in on all our Luupy updates!
                        </h2>

                        <br />
                        <form
                          className="site-form"
                          id="emailAddressSubscribe"
                          onSubmit={(event) => {
                            event.preventDefault();

                            var emailSubscribe = this.state.email;

                            firebase
                              .database()
                              .ref("Admin/Email_Subcribers/")
                              .push({
                                email: emailSubscribe,
                              })
                              .then(() => {
                                this.setState({
                                  contactFormSubmittedResult: "success",
                                });
                              })
                              .catch((error) => {
                                console.log(error);
                                this.setState({
                                  contactFormSubmittedResult: "error",
                                });
                              });
                          }}
                        >
                          <label>
                            <input
                              className="noOutline"
                              type="email"
                              id="emailSubscribe"
                              placeholder="Enter your email here"
                              required="required"
                              onChange={(event) => {
                                this.setState({ email: event.target.value });
                              }}
                            />
                          </label>
                          <input
                            type="submit"
                            value="SUBMIT"
                            className="btn-subscribe"
                          />
                        </form>
                        <div style={{ paddingTop: 10 }}>
                          {this.render_contactFormSubmittedResult()}
                          <div id="contactFormSubmittedResult"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Features;

/*

<div id="howitworks">
              <Row className="align-items-center pt-4">
                <Col md="6" sm="8">
                  <div>
                    <div className="cd_iphone_slider">
                      <div id="slider">
                        <div
                          className="carousel slide"
                          id="carousel-example-generic"
                          data-ride="carousel"
                        >
                          <div className="row ">
                            <div
                              className="carousel-inner cd_items tex width100per"
                              role="listbox"
                            >
                              <Carousel
                                autoPlay={true}
                                infiniteLoop={true}
                                showArrows={false}
                                showStatus={false}
                                showIndicators={false}
                                showThumbs={false}
                              >
                                <div className="item">
                                  <img alt="" src={App_Screen_1} />
                                </div>
                                <div className="item">
                                  <img alt="" src={App_Screen_2} />
                                </div>

                                <div className="item">
                                  <img alt="" src={App_Screen_3} />
                                </div>
                              </Carousel>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md="5" className="ml-auto">
                  <h1 className="section-heading">How this sorcery works</h1>
                  <hr className="black my-4 underline" />
                  <div>
                    <div className="full-container-paragraph-styling">
                      <ul className="list-unstyled ui-steps">
                        <li className="media">
                          <div className="circle-icon mr-4">1</div>
                          <div className="media-body">
                            <h5 className="h-styling fontBoldTextLeft">
                              Link Card
                            </h5>
                            <p className="font20px_textLeft">
                              Link your debit card on the Luup app, so you can
                              automatically get surveys for places you shop at.
                            </p>
                          </div>
                        </li>
                        <li className="media my-4">
                          <div className="circle-icon mr-4">2</div>
                          <div className="media-body">
                            <h5 className="h-styling fontBoldTextLeft">
                              Shop as usual
                            </h5>
                            <p className="font20px_textLeft">
                              The café, grocery store, fav clothing brands –
                              anywhere.
                            </p>
                          </div>
                        </li>
                        <li className="media">
                          <div className="circle-icon mr-4">3</div>
                          <div className="media-body">
                            <h5 className="h-styling fontBoldTextLeft">
                              Share thoughts – earn rewards!
                            </h5>
                            <p className="font20px_textLeft">
                              Automatically receive a short feedback survey on
                              the Luup app. Get points and redeem them for gift
                              cards to your favourite stores!
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                
                </Col>
              </Row>
            </div>
            
            */
