import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

import App_Screen_1 from "../../../../assets/images/App_Screen_1.png";
import App_Screen_2 from "../../../../assets/images/App_Screen_2.png";
import App_Screen_3 from "../../../../assets/images/App_Screen_3.png";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import first from "../../../../assets/images/1st.svg";
import second from "../../../../assets/images/2nd.svg";



class Features extends Component {
  constructor(props) {
    super(props);
    this.state = {
      features1: [
        { id: 1, desc: "Donec pede justo vel aliquet" },
        { id: 2, desc: "Aenean et nisl sagittis" },
      ],
      features2: [
        { id: 1, desc: "Donec pede justo vel aliquet" },
        { id: 2, desc: "Aenean et nisl sagittis" },
      ],
    };
  }

  render_phone(){
    return (
      <div className="text-center">
      <div className="cd_iphone_slider">
        <div id="slider">
          <div
            className="carousel slide"
            id="carousel-example-generic"
            data-ride="carousel"
          >
            <div className="row ">
              <div
                className="carousel-inner cd_items tex width100per"
                role="listbox"
              >
                     <Carousel
        autoPlay={true}
        infiniteLoop={true}
        showArrows={false}
        showStatus={false}
        showIndicators={false}
        showThumbs={false}
      >
        <div className="item">
          <img alt="" src={App_Screen_1} />
        </div>
        <div className="item">
          <img alt="" src={App_Screen_2} />
        </div>

        <div className="item">
          <img alt="" src={App_Screen_3} />
        </div>
      </Carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
  }
  render_how_it_works(){
    return (
      <div>
      <div className="full-container-paragraph-styling">
        <ul className="list-unstyled ui-steps">
          <li className="media">
            <div className="circle-icon mr-4">1</div>
            <div className="media-body">
              <h5 className="h-styling fontBoldTextLeft">
                Link Card
              </h5>
              <p className="font20px_textLeft top_p">
                Link your debit card on the Luup app, so
                you can automatically get surveys for
                places you shop at.
              </p>
            </div>
          </li>
          <li className="media my-4">
            <div className="circle-icon mr-4">2</div>
            <div className="media-body">
              <h5 className="h-styling fontBoldTextLeft">
                Shop as usual
              </h5>
              <p className="font20px_textLeft top_p">
                The café, grocery store, fav clothing
                brands – anywhere.
              </p>
            </div>
          </li>
          <li className="media">
            <div className="circle-icon mr-4">3</div>
            <div className="media-body">
              <h5 className="h-styling fontBoldTextLeft">
                Share thoughts – earn rewards!
              </h5>
              <p className="font20px_textLeft top_p">
                Automatically receive a short feedback
                survey on the Luup app. Get points and
                redeem them for gift cards to your
                favourite stores!
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    )
  }
  render() {
    return (
      <React.Fragment>
        <section
          className="section"
          style={{ background: "#fff", paddingTop:5, paddingBottom:5}}
        >
          <Container className="text-center-mobile-size">
            <Row className="align-items-center pt-4" >
              <Col md="6" sm="12">
                <div>
                  <img
                    src={first}
                    alt=""
                    className="img-fluid mx-auto d-block"
                  />
                </div>
              </Col>
              <Col md="5"  sm="12" className="ml-auto">
                <div className="mb-5">
                 {/* <h2>Rant or Rave. Get rewards you crave.</h2>*/}

                  
                  <h1 className="section-heading section-header">
                  Rant or Rave. Get rewards you crave.
                          </h1>
                          <hr className="black my-4 underline" />


                 {/* <div className="text-muted">*/}
                    <p className="top_p">
                    Redeem points for gift cards to Amazon, Starbucks, Cineplex and more. Bad customer service? Smelly table wipes? Products never in stock? Or did you just have the most amazing experience ever? Let businesses know the good and the bad through the Luup app, and let the right people handle it.
                    </p>
                 {/* </div>*/}
                </div>
              </Col>
            </Row>

            <Row className="align-items-center mt-5 pt-md-5" style={{paddingTop:0,paddingBottom:0}}>
              <Col md="5" sm="12">
                <div className="mb-5">
                 {/*   <h2>Have something to say? Say it!</h2>*/}


                    <h1 className="section-heading section-header">
                    Have something to say? Say it!
                          </h1>
                          <hr className="black my-4 underline" />



                    {/*<div className="text-muted">*/}
                    <p className="top_p">
                      Surveys are short and fun, and your feedback goes anonymously to the right management teams within the companies, so they can work on providing the best experience possible to you and other customers. And the rewards don’t hurt either ;)
                      </p>
                    {/*</div>*/}
                  </div>
              </Col>
              <Col md="6"sm="12" className="ml-md-auto">
                <div className="mt-4 mr-md-0">
                  <img
                    src={second}
                    alt=""
                    className="img-fluid mx-auto d-block"
                  />
                </div>
              </Col>
            </Row>

            {/*<Row className="align-items-center pt-4"   >
              <Col md="6" sm="12" >
                <div>
                {this.render_phone()}
                </div>
              </Col>
              <Col md="5" sm="12"className="ml-auto" id="howitworks">
                 <div className="mb-5">
                 <h1 className="section-heading section-header">
                            How this sorcery works
                          </h1>
                          <hr className="black my-4 underline" />
                {this.render_how_it_works()}
                </div>
              </Col>
            </Row>*/}
          </Container>
        </section>

        <section
          className="section"
          style={{ background: "#fff", paddingTop:5, paddingBottom:5}}
          id="howitworks"
        >
          <Container className="text-center-mobile-size">
        <Row className="align-items-center pt-4"   >
              <Col md="6" sm="12" >
                <div>
                {this.render_phone()}
                </div>
              </Col>
              <Col md="5" sm="12"className="ml-auto" >
                 <div className="mb-5">
                 {/* <h2>How this sorcery works</h2>*/}
                 <h1 className="section-heading section-header">
                            How this sorcery works
                          </h1>
                          <hr className="black my-4 underline" />
                {this.render_how_it_works()}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Features;
