import React, { Component } from "react";
//import { Container, Row, Col } from "reactstrap";

import "react-responsive-carousel/lib/styles/carousel.min.css";
/*import "./css/luup.css";*/
//import "../.././CSS/luup.css";
import Navbar from ".././Navbar/Navbar";
import Footer from ".././Footer/footer";
import Plaid_Logo from "../../../../assets/images/plaid.png";

class Features extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pos: document.documentElement.scrollTop,
      imglight: true,
      navClass: "",
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > this.state.pos) {
      this.setState({ navClass: "nav-sticky", imglight: false });
    } else {
      this.setState({ navClass: "", imglight: true });
    }
  };
  render() {
    return (
      <div>
        <Navbar navClass={this.state.navClass} imglight={this.state.imglight} />
        <section className="bg-white" style={{paddingTop:100}}>
          <div className="container">
            <div className="row container_padding">
              <div className="col-lg-8 mx-auto text-center">
                <h1 className="section-header text-center">Security FAQs</h1>
                <hr className="black my-4 color_hr" />
                <p className="top_p">
                  We take privacy and security seriously, which is why we have
                  put measures in place to ensure that your data is secure and
                  protected. In fact, your personal banking information doesn’t
                  ever go through Luup’s system at all! We have compiled a list
                  of questions you may have, below.
                </p>
                <img
                  src={Plaid_Logo}
                  className="img-fluid autoHeightWidth"
                  alt=""
                />
              </div>
            </div>
          </div>

          <div className="container" style={{ paddingTop: 20 }}>
            <div className="row container_padding">
              <div className="col-lg-12 mx-auto">
                <div className="col-lg-12 col-md-12   col-sm-12 full-container-max d-flex align-items-center ">
                  <div className="content-left-padding-full-container">
                    <div>
                      <div className="full-container-paragraph-styling">
                        <ul className="list-unstyled ui-steps">
                          <li className="media">
                            <div className="circle-icon mr-4">1</div>
                            <div className="media-body">
                            <h5 className="top_p h-styling fontBoldTextLeft">
                                Why do I need to link my cards?
                              </h5>
                              <p className="bullet-styling top_p">
                                Luup asks you to link your cards, so you can
                                automatically receive surveys for the stores
                                you’ve shopped at, and seamlessly earn points
                                and rewards without having to remember to save
                                pesky receipts and codes.
                              </p>
                            </div>
                          </li>
                          <li className="media my-4">
                            <div className="circle-icon mr-4">2</div>
                            <div className="media-body">
                            <h5 className="top_p h-styling fontBoldTextLeft">
                                Do you save my banking credentials?
                              </h5>
                              <p className="bullet-styling top_p">
                                No, Luup NEVER stores any of your banking
                                information. In fact, your banking credentials
                                never go through Luup’s system at all.
                              </p>
                            </div>
                          </li>
                          <li className="media">
                            <div className="circle-icon mr-4">3</div>
                            <div className="media-body">
                            <h5 className="top_p h-styling fontBoldTextLeft">
                                How does Luup link to my account?
                              </h5>
                              <p className="bullet-styling top_p">
                                Luup uses a secure financial service called
                                Plaid Technologies Inc, which is partnered with
                                financial institutions to provide safe and
                                reliable account linkages. Plaid uses bank level
                                encryption to protect your personal data, and is
                                the same service used and trusted by Fortune 50
                                corporations, banks, and leading fintech
                                companies like Venmo, Robinhood, and Acorns.
                              </p>
                            </div>
                          </li>

                          <li className="media">
                            <div className="circle-icon mr-4">4</div>
                            <div className="media-body">
                            <h5 className="top_p h-styling fontBoldTextLeft">
                                What information does Luup see?
                              </h5>
                              <p className="bullet-styling top_p">
                                Luup only receives a list of user transactions,
                                that are stored using bank-level 256 bit end to
                                end encryption, in anonymized encrypted
                                databases.
                              </p>
                            </div>
                          </li>
                          <li className="media my-4">
                            <div className="circle-icon mr-4">5</div>
                            <div className="media-body">
                            <h5 className="top_p h-styling fontBoldTextLeft">
                                Can you see my account balances?
                              </h5>
                              <p className="bullet-styling top_p">
                                No, Luup can never see your account balance or
                                any other personal account information. Luup can
                                ONLY see your transaction-level data.
                              </p>
                            </div>
                          </li>
                          <li className="media">
                            <div className="circle-icon mr-4">6</div>
                            <div className="media-body">
                              <h5 className="top_p h-styling fontBoldTextLeft">
                                I have more questions. Where can I ask them?
                              </h5>
                              <p className="bullet-styling top_p">
                                Reach out to us at support@getluup.com, and
                                we’ll be more than happy to answer any questions
                                or concerns you have!
                              </p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

export default Features;
