import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import app_store_pic from "../../../../assets/images/download-app-store-button.svg";
import android_store_pic from "../../../../assets/images/download-play-store-button.svg";

class Features extends Component {
  render() {
    return (
      <React.Fragment>
        <footer className="landing-footer">
          <Container>
            <Row>
              <Col lg="12">
                <div className="text-center">
                  <h1
                   // className="c"
                   className="section-heading"
                     
                    style={{ color: "white" }}
                  >
                    Download Luup App Now!
                  </h1>

                  {/*<div className="site-social-icons">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://itunes.apple.com/ca/app/luup-rewards-for-feedback/id1459323700?mt=8"
                  >
                    <img
                      // src="images/download-app-store-button.svg"

                      src={require("./images/download-app-store-button.svg")}
                      alt="App Store Download Button"
                    />
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://play.google.com/store/apps/details?id=com.luup.application"
                  >
                    <img
                      //  src="images/download-play-store-button.svg"

                      src={require("./images/download-play-store-button.svg")}
                      alt="Play Store Download Button"
                    />
                  </a>
    </div>*/}

<div className="button-items mt-4" style={{padding:0}}>
                    <a
                      className="btn"
                      style={{padding:0}}
                      target="_blank"
                      href="https://itunes.apple.com/ca/app/luup-rewards-for-feedback/id1459323700?mt=8"
                      rel="noopener noreferrer"
                    >
                       <img
                          src={app_store_pic}
                          alt="App Store Download Button"
                       //   className="padding2-5Px appdownloadbuttonsize"
                        />
                          </a>

                    <a
                       target="_blank"
                       href="https://play.google.com/store/apps/details?id=com.luup.application"
                       rel="noopener noreferrer"
                      className="btn"
                      style={{padding:0}}
                    >
                        <img
                           src={android_store_pic}
                           alt="Play Store Download Button"
                        //   className="padding2-5Px appdownloadbuttonsize"
                         />
                    </a>


                    
                   {/*} <button
                      className="btn btn-light"
                      style={{ color: "black" }}
                    >
                      Get Free Trial
                    </button>
                    */}
                  </div>


                {/*
                  <div className="padding10Px">
                    <a
                      target="_blank"
                      href="https://itunes.apple.com/ca/app/luup-rewards-for-feedback/id1459323700?mt=8"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={app_store_pic}
                        alt="App Store Download Button"
                        className="padding2-5Px appdownloadbuttonsize"
                      />
                    </a>
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.luup.application"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={android_store_pic}
                        alt="Play Store Download Button"
                        className="padding2-5Px appdownloadbuttonsize"
                      />
                    </a>
                  </div>
                  */}

                  <div className="site-social-icons">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.facebook.com/getluup/"
                    >
                      <i className="bx bxl-facebook"></i>
                      {/*
                                            <i className="fa fa-facebook"></i>
 */}
                    </a>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://twitter.com/getluup"
                    >
                      <i className="bx bxl-twitter"></i>
                    </a>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.instagram.com/getluup/"
                    >
                      <i className="bx bxl-instagram"></i>
                    </a>
                  </div>

                  <div className="site-copyright">
                    <p className="site-copyright">
                      © Copyright {new Date().getFullYear()} Luup. Product of
                      Eatonomy Corp.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </React.Fragment>
    );
  }
}

export default Features;
